var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("one-col-box-grid-content-box", [
    _c("h2", [
      _vm._v(_vm._s(_vm.$t("delete_user_confirmation.hl_delete_result")))
    ]),
    _c("ul", [
      _c("li", [
        _vm._v(
          "\n      " +
            _vm._s(_vm.$t("delete_user_confirmation.list_result_expire")) +
            "\n    "
        )
      ]),
      _c("li", [
        _vm._v(
          "\n      " +
            _vm._s(_vm.$t("delete_user_confirmation.list_result_deleted")) +
            "\n    "
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }