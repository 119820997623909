var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-button-link-native",
    {
      attrs: {
        href: _vm.purchaseUrl,
        "data-track-event": _vm.trackingEventComputed,
        "data-track-name": _vm.trackingNameComputed
      },
      on: {
        click: function($event) {
          _vm.noClickBind ? "" : _vm.navigateOrOpenPopup($event)
        }
      },
      nativeOn: {
        click: function($event) {
          return _vm.doNothingIfAlreadyClicked($event)
        }
      }
    },
    [_vm._v("\n  " + _vm._s(_vm.buttonText) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }