var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "coupon-share", class: { loading: _vm.contentLoading } },
    [
      _vm.contentLoading
        ? _c("content-loading-indicator")
        : [
            _c("h2", [_vm._v(_vm._s(_vm.viewTitle))]),
            _c("div", { staticClass: "padded-icon" }, [
              _c("picture", [
                _c("img", { attrs: { src: _vm.iconPath, alt: "" } })
              ]),
              _c(
                "div",
                { staticClass: "content" },
                [
                  _c("h3", { staticClass: "icon-spaced" }, [
                    _vm._v(_vm._s(_vm.productName))
                  ]),
                  _c("p", { staticClass: "icon-spaced" }, [
                    _vm._v(_vm._s(_vm.productDescription))
                  ]),
                  _c("div", { staticClass: "hint-box" }, [
                    _c("picture", [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/icons/icon_star.svg"),
                          alt: "",
                          width: "26",
                          height: "26px"
                        }
                      })
                    ]),
                    _c("p", {
                      staticClass: "icon-spaced",
                      domProps: { innerHTML: _vm._s(_vm.productHint) }
                    })
                  ]),
                  _c("h4", [_vm._v(_vm._s(_vm.listTitle))]),
                  _c("div", {
                    domProps: { innerHTML: _vm._s(_vm.listContent) }
                  }),
                  _c(
                    "base-button",
                    {
                      staticClass: "primary",
                      on: { click: _vm.postAddCoupon }
                    },
                    [_vm._v(_vm._s(_vm.buttonText))]
                  ),
                  _c(
                    "div",
                    { staticClass: "voucher-grid" },
                    _vm._l(_vm.licenses, function(license) {
                      return _c("voucher-item", {
                        key: license,
                        attrs: {
                          heading: _vm.voucherTitleText,
                          voucher: license
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ])
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }