var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "list" }, [
    _c("div", { staticClass: "item" }, [
      _c("div", { staticClass: "title" }, [
        _vm._v(_vm._s(_vm.$t("subscription.hl_product_type")))
      ]),
      _c("div", { staticClass: "title" }, [
        _vm._v(_vm._s(_vm.$t("subscription.hl_license_amount")))
      ]),
      _c("div", { staticClass: "title" }, [
        _vm._v(_vm._s(_vm.$t("subscription.hl_expiration_date")))
      ]),
      _c("div", { staticClass: "title" }, [
        _vm._v(_vm._s(_vm.$t("subscription.hl_renewal")))
      ]),
      _c("div", { staticClass: "value" }, [_vm._v(_vm._s(_vm.productType))]),
      _c("div", { staticClass: "value" }, [_vm._v(_vm._s(_vm.licenseSize))]),
      _c("div", { staticClass: "value" }, [
        _vm._v(_vm._s(_vm.expirationDateFormatted))
      ]),
      _c("div", { staticClass: "value" }, [_vm._v(_vm._s(_vm.renewalTypeText))])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }