<template>
  <div
    class="upgrade-override-popup-content"
    :class="{ reverse: isSafeFirst }"
    v-if="upgradeOverridden"
  >
    <h2>{{ popupTitle }}</h2>
    <div class="choices">
      <a
        href="#"
        class="choice-item"
        :class="{ selected: selectedItem === 1 }"
        @click.prevent="select(1)"
        id="upgrade-popup-override-choice"
      >
        <div class="header">
          <div class="radio-button">
            <img src="../assets/images/icons/radiobutton_off.svg" alt="off" />
            <img src="../assets/images/icons/radiobutton_on.svg" alt="on" />
          </div>
          <div class="text">
            <h3>{{ upgradeOverrideSubscriptionSubtitle }}</h3>
          </div>
        </div>
        <div class="content">
          <div class="image">
            <img src="../assets/images/safe-new.png" alt="" />
          </div>
          <div class="text">
            <p>{{ upgradeSubscriptionDescription }}</p>
          </div>
          <div class="price" v-if="baseProductPrice">
            <p class="device-count">
              {{ upgradeOverrideDevicesText }}
            </p>
            <p class="value">{{ upgradeOverridePrice }}</p>
            <p v-if="isBuyMoreTypeCart">
              {{ $t('upgrade.total_upgrade_text_duration') }}
            </p>
          </div>
        </div>
      </a>
      <a
        href="#"
        class="choice-item"
        :class="{ selected: selectedItem === 2 }"
        @click.prevent="select(2)"
        id="upgrade-popup-safe-choice"
      >
        <div class="header">
          <div class="radio-button">
            <img src="../assets/images/icons/radiobutton_off.svg" alt="off" />
            <img src="../assets/images/icons/radiobutton_on.svg" alt="on" />
          </div>
          <div class="text">
            <h3>{{ baseProductTitle }}</h3>
          </div>
        </div>
        <div class="content">
          <div class="image">
            <template v-if="isIDPSubscription">
              <img src="../assets/images/idp.png" alt="" />
            </template>
            <template v-if="isFreedomeSubscription">
              <img
                src="../assets/images/freedome.png"
                alt=""
                style="margin: 10px;"
              />
            </template>
            <template v-else>
              <img src="../assets/images/safe-new.png" alt="" />
            </template>
          </div>
          <div class="text">
            <p>{{ baseProductText }}</p>
          </div>
          <div class="price" v-if="baseProductPrice">
            <p class="device-count">
              {{ baseProductDevicesText }}
            </p>
            <p class="value">{{ baseProductPrice }}</p>
            <p v-if="isBuyMoreTypeCart">
              {{ $t('upgrade.total_upgrade_text_duration') }}
            </p>
          </div>
        </div>
      </a>
    </div>
    <a
      id="upgrade-popup-buy-button"
      :href="goToUrl"
      @click.native="doNothingIfClicked"
      class="flex-btn primary"
    >
      <span>{{ $t('upgrade.btn_continue_to_checkout') }}</span>
    </a>
    <p class="bottom-note">{{ $t('upgrade.best_protection_award_hint') }}</p>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  data() {
    return {
      userSelectedItem: 1,
      itemChosenByUser: false,
      clicked: false
    }
  },
  computed: {
    ...mapState({
      safeLicensePeriod: state => state.ui.cart.safe.licensePeriod,
      idpLicensePeriod: state => state.ui.cart.idp.licensePeriod,
      freedomeLicensePeriod: state => state.ui.cart.freedome.licensePeriod,
      upgradeOverrideLicensePeriod: state =>
        state.ui.cart.upgrade_override.licensePeriod
    }),
    ...mapGetters({
      isIDPSubscription: 'customer/contract/isIDPSubscription',
      isFreedomeSubscription: 'customer/contract/isFreedomeSubscription',
      isVMPhase2Subscription: 'customer/isVMPhase2Subscription'
    }),
    safeProductPeriodText() {
      const safeLicensePeriod = this.safeLicensePeriod || ''
      return safeLicensePeriod
        ? this.$t('product_period.' + safeLicensePeriod.toLowerCase())
        : safeLicensePeriod
    },
    idpProductPeriodText() {
      const idpLicensePeriod = this.idpLicensePeriod || ''
      return idpLicensePeriod
        ? this.$t('product_period.' + idpLicensePeriod.toLowerCase())
        : idpLicensePeriod
    },
    freedomeProductPeriodText() {
      const freedomeLicensePeriod = this.freedomeLicensePeriod || ''
      return freedomeLicensePeriod
        ? this.$t('product_period.' + freedomeLicensePeriod.toLowerCase())
        : freedomeLicensePeriod
    },
    upgradeOverrideProductPeriodText() {
      const upgradeOverrideLicensePeriod =
        this.upgradeOverrideLicensePeriod || ''
      if (upgradeOverrideLicensePeriod) {
        return this.$t(
          'product_period.' + upgradeOverrideLicensePeriod.toLowerCase()
        )
      } else {
        return upgradeOverrideLicensePeriod
      }
    },
    baseProductDevicesText() {
      if (this.isIDPSubscription) {
        if (this.isBuyMoreTypeCart) {
          return this.$t('upgrade.x_devices', {
            maxdevices: this.idpDeviceCount
          })
        }
        return this.$t('upgrade.total_upgrade_text_devices_duration', {
          maxdevices: this.idpDeviceCount,
          paidDuration: this.idpProductPeriodText
        })
      } else if (this.isFreedomeSubscription) {
        if (this.isBuyMoreTypeCart) {
          return this.$t('upgrade.x_devices', {
            maxdevices: this.freedomeDeviceCount
          })
        }
        return this.$t('upgrade.total_upgrade_text_devices_duration', {
          maxdevices: this.freedomeDeviceCount,
          paidDuration: this.freedomeProductPeriodText
        })
      } else {
        if (this.isBuyMoreTypeCart) {
          return this.$t('upgrade.x_devices', {
            maxdevices: this.safeDeviceCount
          })
        }
        return this.$t('upgrade.total_upgrade_text_devices_duration', {
          maxdevices: this.safeDeviceCount,
          paidDuration: this.safeProductPeriodText
        })
      }
    },
    upgradeOverrideDevicesText() {
      if (this.isBuyMoreTypeCart) {
        return this.$t('upgrade.x_devices', {
          maxdevices: this.upgradeOverrideDeviceCount
        })
      }
      return this.$t('upgrade.total_upgrade_text_devices_duration', {
        maxdevices: this.upgradeOverrideDeviceCount,
        paidDuration:
          this.safeProductPeriodText ||
          this.idpProductPeriodText ||
          this.freedomeProductPeriodText
      })
    },
    popupTitle() {
      let buyMoreStr = 'upgrade.h1_buymore_upgrade'
      let renewStr = 'upgrade.h1_renew_upgrade'
      if (this.isIDPSubscription) {
        buyMoreStr = 'upgrade.h1_buymore_upgrade_from_idp'
        renewStr = 'upgrade.h1_renew_upgrade_from_idp'
      }
      if (this.isFreedomeSubscription) {
        buyMoreStr = 'upgrade.h1_buymore_upgrade_from_freedome'
        renewStr = 'upgrade.h1_renew_upgrade_from_freedome'
      }
      return this.isBuyMoreTypeCart ? this.$t(buyMoreStr) : this.$t(renewStr)
    },
    baseProductTitle() {
      let text = this.isRenewTypeCart
        ? 'upgrade.h2_renew_subscription'
        : 'upgrade.more_devices_text'
      if (this.isIDPSubscription) {
        text = this.isRenewTypeCart
          ? 'upgrade.h2_renew_idp'
          : 'upgrade.h2_buy_more_idp'
      } else if (this.isFreedomeSubscription) {
        text = this.isRenewTypeCart
          ? 'upgrade.h2_renew_freedome'
          : 'upgrade.h2_buy_more_freedome'
      } else if (this.isVMPhase2Subscription && !this.isRenewTypeCart) {
        text = 'upgrade.more_devices_vm_unlimited_text'
      }
      return this.$t(text)
    },
    baseProductText() {
      let text = this.isRenewTypeCart
        ? 'upgrade.renew_safe_internet_security_text'
        : 'upgrade.more_devices_text'
      if (this.isIDPSubscription) {
        text = this.isRenewTypeCart
          ? 'upgrade.renew_idp_text'
          : 'upgrade.more_devices_idp_text'
      }
      if (this.isFreedomeSubscription) {
        text = this.isRenewTypeCart
          ? 'upgrade.renew_freedome_text'
          : 'upgrade.more_devices_freedome_text'
      }
      return this.$t(text)
    },
    upgradeOverrideSubscriptionSubtitle() {
      let text = 'upgrade.h2_upgrade_subscription'
      if (this.isIDPSubscription) {
        text = 'upgrade.h2_upgrade_total_idp'
      }
      if (this.isFreedomeSubscription) {
        text = 'upgrade.h2_upgrade_total_freedome'
      }
      return this.$t(text)
    },
    upgradeSubscriptionDescription() {
      let text = 'upgrade.more_devices_vm_unlimited_internet_security_text'
      if (this.isIDPSubscription) {
        text = 'upgrade.idp_buymore_total_upgrade_text'
      }
      if (this.isFreedomeSubscription) {
        text = 'upgrade.freedome_buymore_total_upgrade_text'
      }
      return this.$t(text)
    },
    changeDefaultSelectionToTotal() {
      if (this.$store.state.ui.isSafeDefaultUpgradePopupSelection) {
        return false
      }
      return true
    },
    selectedItem() {
      if (!this.itemChosenByUser && !this.changeDefaultSelectionToTotal) {
        return this.isSafeFirst ? 2 : 1
      } else if (!this.itemChosenByUser && this.changeDefaultSelectionToTotal) {
        return this.isSafeFirst ? 1 : 2
      }

      return this.userSelectedItem
    },
    isRenewTypeCart() {
      return this.$store.state.ui.cart.type === 'renew_now'
    },
    isBuyMoreTypeCart() {
      return (
        this.$store.state.ui.cart.type === 'buy_more' ||
        this.$store.state.ui.cart.type === 'buy_now'
      )
    },
    baseProductPrice() {
      if (this.isIDPSubscription) {
        return this.idpPrice
      }
      if (this.isFreedomeSubscription) {
        return this.freedomePrice
      }
      return this.safePrice
    },
    upgradeOverridePrice() {
      return this.$store.state.ui.cart.upgrade_override.price
    },
    safePrice() {
      return this.$store.state.ui.cart.safe.price
    },
    idpPrice() {
      return this.$store.state.ui.cart.idp.price
    },
    freedomePrice() {
      return this.$store.state.ui.cart.freedome.price
    },
    safeDeviceCount() {
      return this.$store.state.ui.cart.safe.deviceCount
    },
    idpDeviceCount() {
      return this.$store.state.ui.cart.idp.deviceCount
    },
    freedomeDeviceCount() {
      return this.$store.state.ui.cart.freedome.deviceCount
    },
    upgradeOverrideDeviceCount() {
      return this.$store.state.ui.cart.upgrade_override.deviceCount
    },
    goToUrl() {
      let url = null
      if (this.$store.state.ui.cart.idp && this.$store.state.ui.cart.idp.url) {
        url = this.$store.state.ui.cart.idp.url
      } else if (
        this.$store.state.ui.cart.freedome &&
        this.$store.state.ui.cart.freedome.url
      ) {
        url = this.$store.state.ui.cart.freedome.url
      } else {
        url = this.$store.state.ui.cart.safe.url
      }
      return this.selectedItem === 1
        ? this.$store.state.ui.cart.upgrade_override.url
        : url
    },
    upgradeOverridden() {
      return !!this.$store.state.ui.cart.upgrade_override.url
    },
    isSafeFirst() {
      return true
    }
  },
  methods: {
    select(item) {
      this.itemChosenByUser = true
      this.userSelectedItem = item
    },
    doNothingIfClicked(event) {
      if (this.clicked) {
        event.preventDefault()
      }

      this.clicked = true
    }
  }
}
</script>

<style lang="scss" scoped>
.upgrade-override-popup-content {
  &.reverse {
    .choices {
      flex-direction: column-reverse;
    }

    .choice-item {
      &:first-child {
        margin-top: 20px;
      }

      + .choice-item {
        margin-top: 0;
      }
    }
  }

  .choices {
    display: flex;
    flex-direction: column;

    + .flex-btn {
      margin-top: 20px;
    }
  }

  .bottom-note {
    color: #999;
    font-size: 11px;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 10px;
  }

  .choice-item {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    user-select: none;

    &.selected {
      > .header {
        background-color: $primary-color;

        .radio-button {
          img {
            &:first-child {
              display: none;
            }
            &:last-child {
              display: inline-block;
            }
          }
        }

        h3 {
          color: #fff;
        }
      }

      > .content {
        border-color: $primary-color;
      }
    }

    + .choice-item {
      margin-top: 20px;
    }

    > .header {
      background: #dddddd;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      align-items: center;
      display: flex;
      justify-content: flex-start;

      .radio-button {
        height: 16px;
        margin: 20px 15px;
        width: 16px;
        flex: 1 0 auto;

        img {
          vertical-align: top;

          &:last-child {
            display: none;
          }
        }
      }

      h3 {
        color: #333;
        margin: 8px 0 12px 0;
        font-weight: 600;
        font-size: 1.286rem;
        text-align: left;
      }

      .text {
        margin-right: 15px;
        width: 100%;
      }
    }

    > .content {
      display: flex;
      flex-direction: column;
      border: 1px solid #ddd;
      border-top: none;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;

      .image {
        padding: 16px 0;
      }

      .text {
        text-align: left;
        padding: 0 0 16px 16px;

        ul {
          padding-left: 25px;
          color: $font-color;
          line-height: 1.4rem;
          margin-bottom: 0;
          margin-top: 1rem;
        }
      }

      .price {
        text-align: left;
        padding: 0 0 16px 16px;
        width: 100%;
        margin-top: 10px;

        p:not(.value) {
          font-style: italic;
        }

        .value {
          color: #333;
          font-weight: bold;
          font-size: 26px;
          margin: 10px 0;
        }
      }
    }
  }

  h2 {
    margin-bottom: 36px;
  }

  p {
    color: $font-color;
    font-size: 14px;
    line-height: 16px;
    margin: 0;
  }
}
@media (min-width: $desktop-min-width) {
  .upgrade-override-popup-content {
    .flex-btn {
      width: auto;
    }
    h2 {
      margin-bottom: 48px;
    }
    .bottom-note {
      max-width: 482px;
      margin: 20px auto 5px auto;
    }
    .choice-item {
      > .content {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        > .image {
          flex: 1 0 auto;
          padding: 0;
        }

        > .text {
          padding: 10px 16px;
          width: 100%;
        }

        > .price {
          text-align: center;
          max-width: 252px;
          padding: 0 16px 0 0;
          margin-top: 0;
        }
      }
    }
  }
}
</style>
