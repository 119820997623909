<template>
  <one-col-box class="grid" v-bind="$props">
    <slot />
  </one-col-box>
</template>

<script>
import OneColBox from './OneColBox.vue'
export default {
  props: {
    startHigher: {
      type: Boolean
    }
  },
  components: {
    OneColBox
  }
}
</script>

<style lang="scss" scoped>
@media (min-width: $desktop-min-width) {
  .one-col-box /deep/ .content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0 40px;
    grid-auto-rows: minmax(100px, auto);
    padding: 0 40px;
  }
}
@media screen and (-ms-high-contrast: active) and (min-width: $desktop-min-width),
  screen and (-ms-high-contrast: none) and (min-width: $desktop-min-width) {
  .one-col-box /deep/ .content {
    display: -ms-grid;
    -ms-grid-columns: 1fr 40px 1fr;
    -ms-grid-rows: 1fr 0px 1fr;

    .box:nth-child(1) {
      -ms-grid-column: 1;
      -ms-grid-row: 1;
    }

    .box:nth-child(2) {
      -ms-grid-column: 3;
      -ms-grid-row: 1;
    }

    .box:nth-child(3) {
      -ms-grid-column: 1;
      -ms-grid-row: 3;
    }

    .box:nth-child(4) {
      -ms-grid-column: 3;
      -ms-grid-row: 3;
    }
  }
}
</style>
