var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-view", {
    ref: "base-view",
    scopedSlots: _vm._u([
      {
        key: "top",
        fn: function() {
          return [
            _c("the-header", { attrs: { "is-nav-shown": "" } }),
            _c("the-title-section", {
              attrs: { title: _vm.$t("subscription.hl_subscription") }
            }),
            _c(
              "one-col-box",
              {
                style: { "margin-bottom": _vm.marginBottom },
                attrs: { "no-padding": "" }
              },
              [
                _vm.isSenseSubscription
                  ? _c("overview-section-sense")
                  : _c("overview-section"),
                _c("sense-section"),
                _c("additional-services-section"),
                !_vm.isSenseSubscription
                  ? _c("subscription-action-content")
                  : _vm._e(),
                _c("billing-details"),
                _c("billing-history"),
                _c("discount-section"),
                _c("avenue-iframe-background")
              ],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "bottom",
        fn: function() {
          return [_c("the-region-picker"), _c("the-help"), _c("the-footer")]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }