var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isVisible
    ? _c(
        "base-settings-content-section",
        { staticClass: "billing-history", attrs: { id: "billing-history-id" } },
        [
          _c("h2", [_vm._v(_vm._s(_vm.$t("subscription.hl_billing_history")))]),
          _c(
            "div",
            { staticClass: "list" },
            _vm._l(_vm.billingHistory, function(item) {
              return _c("billing-history-item", {
                key: item.id,
                attrs: { item: item }
              })
            }),
            1
          ),
          _c("p", { domProps: { innerHTML: _vm._s(_vm.contactToSupportText) } })
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }