var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "one-col-box-grid-content-box",
    [
      _vm.showSuccess
        ? [
            _c("h2", [_vm._v(_vm._s(_vm.$t("cancel_autorenew.hl_cancelled")))]),
            _c("p", [_vm._v(_vm._s(_vm.noticeCancelled))]),
            _c(
              "base-button-link",
              {
                staticClass: "neutral",
                attrs: { to: "/portal/order" },
                nativeOn: {
                  click: function($event) {
                    return _vm.resetData($event)
                  }
                }
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("cancel_autorenew.btn_back")) +
                    "\n    "
                )
              ]
            )
          ]
        : _vm.showError
        ? [
            _c("h2", [
              _vm._v(_vm._s(_vm.$t("cancel_autorenew.hl_cancel_error")))
            ]),
            _c("p", [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("cancel_autorenew.ntc_cancel_error"))
              ),
              _c("br"),
              _vm._v(
                _vm._s(_vm.$t("cancel_autorenew.ntc_cancel_error_retry")) +
                  "\n    "
              )
            ]),
            _c(
              "base-button-link",
              { staticClass: "primary", attrs: { to: "/portal/order" } },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("cancel_autorenew.btn_back")) +
                    "\n    "
                )
              ]
            )
          ]
        : [
            _c("h2", [
              _vm._v(_vm._s(_vm.$t("cancel_autorenew.hl_cancel_subscription")))
            ]),
            _c("p", [_vm._v(_vm._s(_vm.noticeCancel))]),
            _c(
              "base-button-link",
              {
                staticClass: "alert",
                class: { loading: _vm.loading },
                attrs: { "prevent-click": "", to: "/portal/order" },
                nativeOn: {
                  click: function($event) {
                    return _vm.cancelAutorenew($event)
                  }
                }
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("cancel_autorenew.btn_cancelsubscription")) +
                    "\n    "
                )
              ]
            ),
            _c(
              "base-button-link",
              {
                staticClass: "neutral",
                attrs: { to: "/portal/order" },
                nativeOn: {
                  click: function($event) {
                    return _vm.resetData($event)
                  }
                }
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.$t("cancel_autorenew.btn_cancelcancelsubscription")
                    ) +
                    "\n    "
                )
              ]
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }