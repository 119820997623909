<template>
  <base-view>
    <template #top>
      <the-header no-shadow is-even-wider />
      <the-title-section :title="title" is-padded is-mobile-centered />
      <two-col-box is-even-wider>
        <template #col1>
          <the-login-left-section />
        </template>
        <template #col2>
          <the-register-right-section />
        </template>
      </two-col-box>
    </template>
    <template #bottom>
      <the-region-picker />
      <the-help />
      <the-footer is-visible-on-mobile />
    </template>
  </base-view>
</template>
<script>
import TheHeader from '@/components/TheHeader.vue'
import TheTitleSection from '@/components/TheTitleSection.vue'
import TwoColBox from '@/components/TwoColBox.vue'
import TheLoginLeftSection from '@/components/TheLoginLeftSection.vue'
import TheRegisterRightSection from '@/components/TheRegisterRightSection.vue'
import TheHelp from '@/components/TheHelp.vue'
import TheRegionPicker from '@/components/TheRegionPicker.vue'
import TheFooter from '@/components/TheFooter.vue'
import BaseView from '@/components/BaseView.vue'
import { mapState } from 'vuex'

export default {
  components: {
    TheHeader,
    TheTitleSection,
    TwoColBox,
    TheLoginLeftSection,
    TheRegisterRightSection,
    TheRegionPicker,
    TheHelp,
    TheFooter,
    BaseView
  },
  computed: {
    ...mapState({
      currentLocale: state => state.ui.currentLocale
    }),
    title() {
      return this.$t('registration.hl_fsecure_service')
    }
  }
}
</script>

<style lang="scss" scoped>
.one-col-box.error {
  background-color: #f5f5f5;
  min-height: 200px;

  h2 {
    font-size: 1.87rem;
    margin: 0;
    padding-bottom: 20px;
    padding-top: 40px;
    line-height: 1.21886em;
    font-weight: normal;
    color: $font-color;
  }

  p {
    margin: 0;
    padding-bottom: 20px;
    color: rgb(102, 102, 102);

    &:last-child {
      padding-bottom: 40px;
    }
  }
}
</style>
