var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container", staticStyle: { "padding-top": "80px" } },
    [
      _c("img", {
        attrs: { src: require("../assets/images/coupon-apply-success.svg") }
      }),
      _c("h1", [_vm._v(_vm._s(_vm.$t("coupon_apply.success_heading")))])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }