var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "one-col-box-grid-content-box",
    [
      _vm.showSuccess
        ? [
            _c("h2", [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.$t("enable_autorenew.hl_enable_subscription_success")
                  ) +
                  "\n    "
              )
            ]),
            _c("p", [
              _c("span", { staticClass: "icon" }, [
                _c("img", {
                  attrs: {
                    src: require("../assets/images/icons/icon_status_green_fill_small.svg"),
                    alt: ""
                  }
                })
              ]),
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.$t(
                      "enable_autorenew.enable_subscription_success_desc",
                      {
                        currency: _vm.billingCurrency,
                        nextBillingDate: _vm.nextBillingDate,
                        amount: _vm.billingAmount
                      }
                    )
                  ) +
                  "\n    "
              )
            ]),
            _c(
              "base-button-link",
              {
                staticClass: "primary",
                attrs: { to: "/portal/order" },
                nativeOn: {
                  click: function($event) {
                    return _vm.resetData($event)
                  }
                }
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.$t(
                        "enable_autorenew.btn_enable_subscription_success_back"
                      )
                    ) +
                    "\n    "
                )
              ]
            )
          ]
        : [
            _c("h2", [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("enable_autorenew.hl_enable_subscription")) +
                  "\n    "
              )
            ]),
            _c("p", [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.$t(
                      "enable_autorenew.hl_enable_subscription_description"
                    )
                  ) +
                  "\n    "
              )
            ]),
            _c("p", [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.$t(
                      "enable_autorenew.hl_enable_subscription_description2",
                      {
                        nextBillingDate: _vm.nextBillingDate
                      }
                    )
                  ) +
                  "\n    "
              )
            ]),
            _c(
              "base-button-link",
              {
                staticClass: "primary",
                class: { loading: _vm.loading },
                attrs: { "prevent-click": "", to: "/portal/order" },
                nativeOn: {
                  click: function($event) {
                    return _vm.enableAutorenew($event)
                  }
                }
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("enable_autorenew.btn_enable")) +
                    "\n    "
                )
              ]
            ),
            _c(
              "base-button-link",
              {
                staticClass: "neutral",
                attrs: { to: "/portal/order" },
                nativeOn: {
                  click: function($event) {
                    return _vm.resetData($event)
                  }
                }
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("enable_autorenew.btn_cancel_back")) +
                    "\n    "
                )
              ]
            ),
            _c("p", [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.$t(
                      "enable_autorenew.hl_enable_subscription_description3",
                      {
                        nextBillingDate: _vm.nextBillingDate,
                        currency: _vm.billingCurrency,
                        amount: _vm.billingAmount
                      }
                    )
                  ) +
                  "\n    "
              )
            ])
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }