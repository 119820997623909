<template>
  <div class="inner-content">
    <div class="activation-view" v-show="!accepted">
      <div class="info-wrapper" :class="{ 'left-aligned': isInfoLeftAligned }">
        <h2>
          {{
            $t('activation.hl_activate_service', { servicename: serviceName })
          }}
        </h2>
        <p v-html="infoText"></p>
        <p v-html="learnMoreText"></p>
        <p v-html="termsText"></p>
      </div>
      <div class="buttons">
        <base-button
          id="btn-additional-service-activate"
          data-track-event="navigate"
          data-track-name="activate-service"
          class="primary"
          @click="sendAccept"
        >
          {{ $t('activation.btn_accept_and_activate') }}
        </base-button>
        <base-button
          id="btn-additional-service-no-thanks"
          data-track-event="navigate"
          data-track-name="cancel-activate-service"
          class="neutral grey"
          @click="closePopup"
        >
          {{ $t('activation.btn_no_thanks') }}
        </base-button>
      </div>
    </div>
    <div class="activated-view" v-show="accepted">
      <h2>
        {{ $t('activation.hl_congratulations') }}
      </h2>
      <picture>
        <img
          src="../assets/images/icons/icon_status_green_small.svg"
          alt="check"
        />
      </picture>
      <h3 class="success">
        {{
          $t(successMessageLocalizationKey, {
            servicename: serviceName
          })
        }}
      </h3>
      <p v-if="isActivatedOnlyOnMyfs">
        {{
          $t('activation.ntc_to_take_advantage_of_benefit_go_to_subscription', {
            servicename: serviceName
          })
        }}
      </p>
      <div class="buttons">
        <base-button
          id="btn-additional-service-close"
          data-track-event="navigate"
          data-track-name="close-activate-service"
          class="primary"
          @click="closePopup"
        >
          {{ closeButtonTextComputed }}
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import ActivationService from '@/services/ActivationService'
import BaseButton from './BaseButton'
import DataMixin from '@/mixins/data'
export default {
  data() {
    return {
      accepted: false
    }
  },
  mixins: [DataMixin],
  components: {
    BaseButton
  },
  props: {
    closePopup: {
      type: Function
    },
    closeButtonText: {
      type: String
    },
    infoText: {
      type: String
    },
    isInfoLeftAligned: {
      type: Boolean
    },
    isActivatedOnlyOnMyfs: {
      type: Boolean
    },
    showInstructionsPopup: {
      type: Boolean
    },
    serviceName: {
      type: String
    },
    learnmoreUrl: {
      type: String
    },
    termsUrl: {
      type: String
    },
    serviceId: {
      type: Number
    }
  },
  computed: {
    closeButtonTextComputed() {
      if (this.$props.closeButtonText) {
        return this.$props.closeButtonText
      }

      return this.$t('activation.btn_close')
    },
    successMessageLocalizationKey() {
      return this.$props.isActivatedOnlyOnMyfs
        ? 'activation.ntc_service_activated_in_myfs'
        : 'activation.ntc_service_activated'
    },
    termsText() {
      return this.$t('activation.ntc_accept_terms', {
        'service name': this.$props.serviceName,
        linkstart:
          '<a href="' +
          this.$props.termsUrl +
          '" data-track-event="navigate" data-track-name="terms-of-service" target="_blank" rel="noopener noreferrer">',
        linkend: '</a>'
      })
    },
    learnMoreText() {
      return this.$t('activation.ntc_learn_more', {
        servicename: this.$props.serviceName,
        linkstart:
          '<a href="' +
          this.$props.learnmoreUrl +
          '" data-track-event="navigate" data-track-name="learn-more" target="_blank" rel="noopener noreferrer">',
        linkend: '</a>'
      })
    }
  },
  methods: {
    sendAccept() {
      this.preHandleResponse(() =>
        ActivationService.postActivation(
          this.$props.serviceId,
          this.$store.state.customer.token
        )
      ).then(response => {
        if (!response || response.errorCode) {
          return
        }
        this.$emit('service-activated')
        this.accepted = true
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.inner-content {
  max-width: 558px;
  margin: 0 auto;

  h2 {
    margin-bottom: 3.6rem;
  }

  .left-aligned {
    text-align: left;
  }

  .activated-view {
    h2 {
      margin-bottom: 2rem;
    }

    .buttons {
      margin-top: 3.1rem;
    }
  }

  h3 {
    font-size: 2.2rem;
    margin: 2.5rem 0;
    font-weight: 600;
    line-height: 2.6rem;

    &.success {
      color: $ok-color;
    }
  }

  picture {
    width: 73px;
    height: 73px;
    display: inline-block;
    margin: 0 auto;
  }

  p {
    font-size: 1.143rem;
    line-height: 1.429rem;
    margin: 1.714rem 0;
  }

  .buttons {
    margin-top: 5.286rem;
  }
}
@media (min-width: 768px) {
  .flex-btn {
    width: auto;
    min-width: 244px;
    + .flex-btn {
      margin-left: 10px;
    }
  }
}
</style>
