<template>
  <base-view is-blue-bg-view overlayed>
    <template #top>
      <the-header is-nav-shown />
      <one-col-box-with-close
        no-transform
        add-space-to-top
        apply-top-margin
        close-button-url="/portal/order"
        less-close-padding
      >
        <base-modal-popup-content more-padding>
          <the-upgrade-override-popup-content />
          <the-upgrade-popup-content />
        </base-modal-popup-content>
      </one-col-box-with-close>
    </template>
    <template #bottom>
      <the-region-picker />
      <the-help />
      <the-footer />
    </template>
  </base-view>
</template>
<script>
import TheHeader from '@/components/TheHeader.vue'
import TheHelp from '@/components/TheHelp.vue'
import TheRegionPicker from '@/components/TheRegionPicker.vue'
import TheFooter from '@/components/TheFooter.vue'
import BaseView from '@/components/BaseView.vue'
import OneColBoxWithClose from '@/components/OneColBoxWithClose.vue'
import TheUpgradeOverridePopupContent from '@/components/TheUpgradeOverridePopupContent.vue'
import TheUpgradePopupContent from '@/components/TheUpgradePopupContent.vue'
import BaseModalPopupContent from '@/components/BaseModalPopupContent.vue'

export default {
  components: {
    TheHeader,
    TheRegionPicker,
    TheHelp,
    TheFooter,
    BaseView,
    OneColBoxWithClose,
    TheUpgradePopupContent,
    TheUpgradeOverridePopupContent,
    BaseModalPopupContent
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.path === '/') {
        if (!vm.$store.state.ui.cart.type) {
          vm.$store.commit('ui/SET_WAIT_FOR_REDIRECT', true)
        }
      }
      vm.$root.$emit('route-entered', to.path, from.path)
    })
  }
}
</script>

<style lang="scss" scoped>
/deep/ .one-col-box .content {
  padding: 0;
}

@media (min-width: $desktop-min-width) {
  /deep/ .one-col-box {
    max-width: 900px;
  }
}
</style>
