<template>
  <base-settings-content-section class="summary" id="summary-id">
    <h2 id="hl-subscription-summary">
      {{ $t('subscription.hl_subscription_summary') }}
    </h2>
    <div class="grid" id="subscription-summary-item">
      <subscription-view-summary-item
        :title="$t('subscription.hl_product_type')"
        :content="productType"
      />
      <subscription-view-summary-item
        :title="$t('subscription.hl_expiration_date')"
        :content="expirationDateFormatted"
        v-if="displayExpirationDate"
      />
      <subscription-view-summary-item
        :title="$t('subscription.hl_status')"
        :content="subscriptionStatusText"
      />
    </div>
  </base-settings-content-section>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import BaseSettingsContentSection from './BaseSettingsContentSection.vue'
import SubscriptionViewSummaryItem from './SubscriptionViewSummaryItem.vue'
export default {
  components: {
    BaseSettingsContentSection,
    SubscriptionViewSummaryItem
  },
  computed: {
    ...mapState({
      expirationDate: state => state.customer.contract.expirationDate,
      daysUntilExpiration: state => state.customer.contract.daysUntilExpiration
    }),
    ...mapGetters({
      isSafeSubscription: 'customer/contract/isSafeSubscription',
      isTotalSubscription: 'customer/contract/isTotalSubscription',
      isTotalIDMSubscription: 'customer/contract/isTotalIDMSubscription',
      isSenseSubscription: 'customer/contract/isSenseSubscription',
      isManualSubscription: 'customer/contract/isManualSubscription',
      isPrepaid: 'customer/contract/isPrepaid',
      isTrial: 'customer/contract/isTrial',
      expirationDateFormatted: 'customer/contract/expirationDateFormatted',
      productNames: 'ui/productNames'
    }),
    // unified subscription does not support SENSE
    productType() {
      if (this.isSafeSubscription) {
        return this.productNames.safeFull
      }

      if (this.isTotalIDMSubscription) {
        return this.productNames.totalIdmFull
      }

      if (this.isTotalSubscription) {
        return this.productNames.totalFull
      }

      if (this.isSenseSubscription) {
        return this.productNames.senseFull
      }

      return ''
    },
    displayExpirationDate() {
      return this.isManualSubscription && this.expirationDate !== null
    },
    subscriptionStatusText() {
      return this.daysUntilExpiration === null ||
        (this.expirationDate !== null &&
          this.daysUntilExpiration !== null &&
          this.daysUntilExpiration >= 0)
        ? this.$t('subscription.txt_active')
        : this.$t('subscription.txt_inactive')
    }
  }
}
</script>

<style lang="scss" scoped>
.settings-content /deep/ {
  padding: 33px 40px;

  h2 {
    margin-top: 0;
    margin-bottom: 38px;
  }

  h3 {
    line-height: 1.6rem;
  }

  p {
    line-height: 1.6rem;
    color: $font-color;
  }
}

.grid {
  display: grid;
  grid-gap: 24px 20px;
  grid-template-columns: 1fr;

  .item /deep/ {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > .content {
      flex: 1 0 100%;
    }
  }
}

@media (min-width: $desktop-min-width) {
  .settings-content /deep/ {
    padding: 40px;
  }
  .grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

// IE11
@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  .grid /deep/ {
    display: -ms-grid;
    -ms-grid-columns: 1fr;
    .item {
      -ms-flex-flow: row wrap;
      .title {
        padding-bottom: 3px;
      }
      &:nth-child(1) {
        -ms-grid-row: 1;
        -ms-grid-column: 1;
        padding-bottom: 20px;
      }
      &:nth-child(2) {
        -ms-grid-row: 2;
        -ms-grid-column: 1;
      }
    }
  }
}
@media screen and (-ms-high-contrast: active) and (min-width: $desktop-min-width),
  screen and (-ms-high-contrast: none) and (min-width: $desktop-min-width) {
  .grid /deep/ {
    -ms-grid-columns: 1fr 1fr 1fr;
    .item {
      .title {
        padding-bottom: 10px;
      }
      &:nth-child(1) {
        -ms-grid-row: 1;
        -ms-grid-column: 1;
        padding-bottom: 0;
      }
      &:nth-child(2) {
        -ms-grid-row: 1;
        -ms-grid-column: 2;
      }
    }
  }
}
</style>
