var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-view", {
    attrs: { "is-blue-bg-view": "", overlayed: "" },
    scopedSlots: _vm._u([
      {
        key: "top",
        fn: function() {
          return [
            _c("the-header", { attrs: { "is-nav-shown": "" } }),
            _c(
              "one-col-box-with-close",
              {
                attrs: {
                  "no-transform": "",
                  "add-space-to-top": "",
                  "apply-top-margin": "",
                  "close-button-url": "/portal/order"
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "popup-content" },
                  [
                    _c("h2", [
                      _vm._v(
                        _vm._s(_vm.$t("enable_autorenew_reason.hl_reason"))
                      )
                    ]),
                    _c("ul", [
                      _c("li", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("enable_autorenew_reason.reason1")
                          )
                        }
                      }),
                      _c("li", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("enable_autorenew_reason.reason2")
                          )
                        }
                      }),
                      _c("li", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("enable_autorenew_reason.reason3")
                          )
                        }
                      })
                    ]),
                    _c(
                      "base-button-link",
                      {
                        staticClass: "neutral",
                        attrs: { to: "/portal/order" }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("enable_autorenew_reason.btn_back")) +
                            "\n        "
                        )
                      ]
                    )
                  ],
                  1
                )
              ]
            )
          ]
        },
        proxy: true
      },
      {
        key: "bottom",
        fn: function() {
          return [_c("the-region-picker"), _c("the-help"), _c("the-footer")]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }