<template>
  <base-modal-popup
    id="subscription-reminder"
    class="subscription-reminder"
    v-show="isOpen"
    :display-footer="displayFooter"
    display-close-button
    :on-close-click="closePopup"
    more-padding
    :class="{ notice: isNotice, alert: isAlert, ok: isOk }"
    is-wide
    :more-footer-padding="!isFooterTextEmpty"
  >
    <template>
      <h2>{{ popupTitle }}</h2>
      <div
        class="content-row"
        :class="{
          'valign-to-top': teaserText,
          centered: isContentCentered,
          'no-bottom-padding': isButtonTextEmpty
        }"
      >
        <div class="icon" v-if="popupImage">
          <img :src="popupImage" alt />
        </div>
        <div class="text">
          <h3 v-if="popupSubTitle">{{ popupSubTitle }}</h3>
          <p v-html="popupText.replace(/\n/g, '<br>')"></p>
          <p v-if="showSecondParagraph">
            <span
              v-if="popupTextSecond"
              v-html="popupTextSecond.replace(/\n/g, '<br />') + '&nbsp;'"
            ></span>
            <a v-if="contentLinkText" :href="contentLinkUrl">
              {{ contentLinkText }}
            </a>
          </p>
          <p class="teaser" v-if="teaserText">{{ teaserText }}</p>
        </div>
      </div>
      <base-purchase-button
        v-if="buttonText"
        class="primary"
        :text="buttonText"
        :url="buttonUrl"
        @click.native="closeOrNavigate($event)"
        ref="purchase-button"
        :no-click-bind="!navigateToBuy"
      />
      <base-button
        class="neutral"
        v-if="showSecondButton"
        @click="closePopup"
        >{{ $t('reminder.btn_remindmelater') }}</base-button
      >
    </template>
    <template #footer>
      <p v-if="footerText" v-html="footerText"></p>
      <base-button-link-native
        v-if="footerButtonText"
        class="primary"
        :href="footerButtonUrl"
        >{{ footerButtonText }}</base-button-link-native
      >
      <router-link
        v-if="showFooterCouponLink"
        @click.native="closePopup"
        to="/portal/coupon/renew"
      >
        {{ footerLinkText }}
      </router-link>
    </template>
  </base-modal-popup>
</template>

<script>
import popup from '@/mixins/popup.js'
import { mapState, mapGetters } from 'vuex'
import transchoice from '@/mixins/transchoice.js'
import BaseModalPopup from './BaseModalPopup.vue'
import BaseButton from './BaseButton.vue'
import BaseButtonLinkNative from './BaseButtonLinkNative.vue'
import BasePurchaseButton from './BasePurchaseButton.vue'
import XmlLocalizeService from '@/services/XmlLocalizeService.js'

export default {
  data() {
    return {
      clicked: false
    }
  },
  props: {
    evaluateShowOnMount: {
      type: Boolean,
      default: false
    }
  },
  mixins: [transchoice, popup],
  components: {
    BaseModalPopup,
    BaseButton,
    BasePurchaseButton,
    BaseButtonLinkNative
  },
  watch: {
    isOpen(newValue, oldValue) {
      if (
        (oldValue === false && newValue === true) ||
        (oldValue === true && newValue === false)
      ) {
        this.$store.commit('ui/SET_IS_REMINDER_POPUP_OPEN', newValue)
      }
    }
  },
  computed: {
    ...mapState({
      affiliate: state => state.customer.affiliate,
      isAutomaticSubscription: state =>
        state.customer.contract.isAutomaticSubscription,
      daysUntilExpiration: state => state.customer.contract.daysUntilExpiration,
      reminderDate: state => state.customer.contract.reminderDate,
      expirationDate: state => state.customer.contract.expirationDate,
      customerLoggedIn: state => state.customer.isLoggedIn,
      reminderPopupTeaser: state => state.ui.reminderPopupTeaser,
      currentLocale: state => state.ui.currentLocale,
      cartType: state => state.ui.cart.type,
      reminderPopupButtonRenewSafe: state =>
        state.ui.reminderPopupButtonRenewSafe,
      reminderPopupButtonRenewTotal: state =>
        state.ui.reminderPopupButtonRenewTotal,
      safeBuyUrl: state => (state.ui.cart.safe ? state.ui.cart.safe.url : null),
      idpBuyUrl: state => (state.ui.cart.idp ? state.ui.cart.idp.url : null),
      freedomeBuyUrl: state =>
        state.ui.cart.freedome ? state.ui.cart.freedome.url : null,
      totalBuyUrl: state => state.ui.cart.total.url,
      upgradeOverrideBuyUrl: state => state.ui.cart.upgrade_override.url,
      inCouponRenewalPeriod: state =>
        state.customer.contract.inCouponRenewalPeriod,
      licensePeriod: state => state.customer.contract.licensePeriod
    }),
    ...mapGetters({
      isSubUser: 'customer/contract/isSubUser',
      isPrepaid: 'customer/contract/isPrepaid',
      isTrial: 'customer/contract/isTrial',
      isSafeSubscription: 'customer/contract/isSafeSubscription',
      isEppSubscription: 'customer/contract/isEppSubscription',
      isTotalSubscription: 'customer/contract/isTotalSubscription',
      isTotalStandardSubscription:
        'customer/contract/isTotalStandardSubscription',
      isManualSubscription: 'customer/contract/isManualSubscription',
      expirationDateFormatted: 'customer/contract/expirationDateFormatted',
      remindBeforeDaysAmount: 'customer/contract/remindBeforeDaysAmount',
      customerIsSuspended: 'customer/isSuspended',
      isContractWaitingForPayment: 'customer/isContractWaitingForPayment',
      isPaymentFailing: 'customer/isPaymentFailing',
      isContractOnHold: 'customer/contract/isOnHold',
      isIDPSubscription: 'customer/contract/isIDPSubscription',
      isFreedomeSubscription: 'customer/contract/isFreedomeSubscription',
      productNames: 'ui/productNames',
      isUnified: 'customer/isUnified',
      unifiedProductNames: 'ui/unifiedProductNames',
      isTotalStandardIdMonitoringSubscription:
        'customer/contract/isTotalStandardIdMonitoringSubscription',
      getFullUnifiedProductName: 'customer/contract/getFullUnifiedProductName'
    }),
    subscriptionOkDays() {
      return this.remindBeforeDaysAmount
    },
    popupTitle() {
      if (this.isPaymentNotReceived) {
        return this.$t('reminder.hl_payment')
      }

      if (this.isChargedBack) {
        return this.$t('reminder.hl_chargeback_subscription_cancelled')
      }

      if (this.isRefunded) {
        return this.$t('reminder.hl_thankyou_for_using')
      }

      if (this.isWaitingForPayment) {
        return this.$t('reminder.hl_waiting_for_payment')
      }

      if (this.isAlert) {
        if (this.isAutomaticSubscription || this.customerIsSuspended) {
          return this.$t('reminder.hl_subscription_expired')
        }

        if (this.isManualSubscription) {
          return this.$t('reminder.hl_renew_now')
        }

        return this.$t('reminder.hl_buy_now')
      }

      if (this.isNotice) {
        return this.$t('reminder.hl_stay_protected')
      }

      if (this.isOk) {
        return this.$t('reminder.hl_thankyou')
      }

      return ''
    },
    popupSubTitle() {
      if (
        this.isWaitingForPayment ||
        this.isRefunded ||
        this.isChargedBack ||
        this.isPaymentNotReceived
      ) {
        return ''
      }

      if (this.isAlert) {
        if (this.isSubUser) {
          return this.$t('reminder.ntc_subuser_subscription_expired')
        }

        if (this.isTrial) {
          return this.$t('reminder.ntc_trial_expired')
        }

        if (this.isAutomaticSubscription) {
          return this.$t('reminder.ntc_autorenew_expired_alert')
        }

        return this.$t('reminder.ntc_subscription_expired')
      }

      if (this.isNotice) {
        if (this.isSubUser) {
          return this.transchoice(
            this.$t('reminder.ntc_subuser_subscription_expires_in_days', {
              days: this.daysUntilExpiration
            }),
            this.daysUntilExpiration
          )
        }

        if (this.isTrial) {
          return this.transchoice(
            this.$t('reminder.ntc_trial_expires_in_days', {
              days: this.daysUntilExpiration
            }),
            this.daysUntilExpiration
          )
        }

        return this.transchoice(
          this.$t('reminder.ntc_subscription_expires_in_days', {
            days: this.daysUntilExpiration
          }),
          this.daysUntilExpiration
        )
      }

      return this.$t('reminder.hl_manualrenew_renewed')
    },
    popupImage() {
      if (
        this.isWaitingForPayment ||
        this.isRefunded ||
        this.isChargedBack ||
        this.isPaymentNotReceived
      ) {
        return ''
      }

      if (this.isAlert) {
        return require('../assets/images/icons/icon_status_red_big.svg')
      }

      if (this.isNotice) {
        return require('../assets/images/icons/icon_status_yellow_big.svg')
      }

      return require('../assets/images/icons/icon_status_green_big.svg')
    },
    popupText() {
      if (this.isPaymentNotReceived) {
        return (
          this.$t('reminder.ntc_waiting_for_payment_confirmed') +
          '\n\n' +
          this.$t('reminder.ntc_stay_protected_confirmed', {
            days: this.daysUntilExpiration
          })
        )
      }

      if (this.isChargedBack) {
        return this.$t('reminder.ntc_chargeback_subscription_cancelled')
      }

      if (this.isRefunded) {
        return (
          this.$t('reminder.ntc_refunded_your_subscription_cancelled') +
          '\n\n' +
          this.$t('reminder.ntc_refunded_safe_not_protecting')
        )
      }

      if (this.isWaitingForPayment) {
        return this.$t('reminder.ntc_start_protecting')
      }

      if (this.isAlert) {
        if (this.isSubUser) {
          if (this.isSafeSubscription || this.isEppSubscription) {
            return this.$t('reminder.ntc_subuser_prepaid_expired_description')
          }

          return this.$t(
            'reminder.ntc_subuser_prepaid_expired_description_total'
          )
        }

        if (this.isPrepaid) {
          if (this.isSafeSubscription || this.isEppSubscription) {
            return this.$t('reminder.ntc_renew_now_to_continue')
          }

          return this.$t('reminder.ntc_renew_now_to_continue_total')
        }

        if (this.isAutomaticSubscription) {
          if (this.isSafeSubscription || this.isEppSubscription) {
            return this.$t('reminder.ntc_autorenew_expired_description')
          }

          return this.$t('reminder.ntc_autorenew_expired_description_total')
        }

        return this.$t('reminder.ntc_not_protected')
      }

      if (this.isNotice) {
        if (this.isSubUser) {
          if (this.isSafeSubscription || this.isEppSubscription) {
            return this.$t('reminder.ntc_subuser_stay_protected_renew')
          }

          return this.$t('reminder.ntc_subuser_stay_protected_renew_total')
        }

        if (this.isTrial) {
          if (this.isSafeSubscription || this.isEppSubscription) {
            return this.$t('reminder.ntc_subscription_buynow')
          }

          return this.$t('reminder.ntc_subscription_buynow_total')
        }

        if (this.isSafeSubscription || this.isEppSubscription) {
          return this.$t('reminder.ntc_renewnow')
        }

        return this.$t('reminder.ntc_renewnow_total')
      }

      return this.$t('reminder.ntc_devices_valid_until', {
        date: this.expirationDateFormatted
      })
    },
    popupTextSecond() {
      if (this.isChargedBack) {
        return this.$t('reminder.ntc_chargeback_safe_not_protecting')
      }

      if (this.isAlert) {
        if (this.isSubUser) {
          return ''
        }

        if (this.isAutomaticSubscription) {
          return ''
        }

        // if (this.isTrial) {
        //   return this.$t('reminder.ntc_subscription_buynow_total')
        // }

        // if (this.isSafeSubscription || this.isEppSubscription) {
        //   return this.$t('reminder.ntc_renew_now_to_continue')
        // }

        // return this.$t('reminder.ntc_renew_now_to_continue_total')
      }

      return ''
    },
    isButtonTextEmpty() {
      return this.buttonText === ''
    },
    customButtonRenewTextSafe() {
      return XmlLocalizeService.getTranlationText.bind(this)(
        this.reminderPopupButtonRenewSafe,
        this.currentLocale,
        {}
      )
    },
    customButtonRenewTextTotal() {
      return XmlLocalizeService.getTranlationText.bind(this)(
        this.reminderPopupButtonRenewTotal,
        this.currentLocale,
        {}
      )
    },
    customButtonRenewText() {
      if (this.isSafeSubscription || this.isEppSubscription) {
        if (this.customButtonRenewTextSafe) {
          return this.customButtonRenewTextSafe
        }
      }

      if (
        this.isTotalSubscription ||
        this.isTotalStandardSubscription ||
        this.isTotalStandardIdMonitoringSubscription
      ) {
        if (this.customButtonRenewTextTotal) {
          return this.customButtonRenewTextTotal
        }
      }

      return ''
    },
    buttonText() {
      if (this.isContractOnHold) {
        return this.$t('reminder.btn_edit_subscription')
      }

      if (this.isPaymentFailing) {
        return this.$t('reminder.btn_edit_subscription')
      }

      if (this.isPaymentNotReceived) {
        return this.$t('reminder.ntc_view_orders_and_payments')
      }

      if (this.isChargedBack) {
        return this.$t('reminder.btn_buy_now')
      }

      if (this.isRefunded) {
        return ''
      }

      if (this.isSubUser) {
        return this.$t('reminder.btn_ok')
      }

      if (this.isWaitingForPayment) {
        return this.$t('reminder.ntc_check_orders_and_payments')
      }

      if (this.isOk) {
        return this.$t('reminder.ntc_button_ok_awesome')
      }

      if (this.isAlert) {
        if (this.isAutomaticSubscription) {
          return this.$t('reminder.btn_edit_subscription')
        }
      }

      if (this.cartType === 'renew_now') {
        if (this.customButtonRenewText) {
          return this.customButtonRenewText
        }
        return this.$t('subscription.btn_renew_now')
      }

      if (this.cartType === 'buy_now') {
        return this.$t('subscription.btn_buy_now')
      }

      if (this.cartType === 'buy_more') {
        return this.$t('subscription.btn_buy_more')
      }

      return this.$t('reminder.btn_buy_now')
    },
    buttonUrl() {
      if (this.isSubUser) {
        return '#'
      }

      if (this.isPaymentNotReceived) {
        return '/portal/order'
      }

      if (this.isChargedBack) {
        return ''
      }

      if (this.isWaitingForPayment) {
        return '/portal/order'
      }

      if (this.isOk) {
        return '#'
      }

      if (this.isAlert) {
        if (this.isAutomaticSubscription) {
          return '/portal/order'
        }
      }

      if (
        (this.safeBuyUrl || this.idpBuyUrl || this.freedomeBuyUrl) &&
        (this.totalBuyUrl || this.upgradeOverrideBuyUrl)
      ) {
        return '/portal/product/upgrade'
      }

      return ''
    },
    navigateToBuy() {
      return this.isChargedBack
    },
    contentLinkText() {
      return ''
    },
    contentLinkUrl() {
      return ''
    },
    footerText() {
      if (this.isRefunded) {
        return this.$t('reminder.ntc_refunded_sorry_to_see_you_go').replace(
          /\n/g,
          '<br>'
        )
      }
      return ''
    },
    footerButtonText() {
      if (this.isRefunded) {
        return this.$t('reminder.btn_give_us_feedback')
      }

      return ''
    },
    footerButtonUrl() {
      if (this.isRefunded) {
        return 'https://community.f-secure.com'
      }

      return ''
    },
    footerLinkText() {
      let productName = ''
      if (this.isUnified) {
        productName = this.getFullUnifiedProductName
      } else {
        productName = this.productNames.safeFull
        if (this.isIDPSubscription) {
          productName = this.productNames.keyFull
        }
        if (this.isFreedomeSubscription) {
          productName = this.productNames.freedomeFull
        }
      }

      return this.$t('registration.ntc_modal_footer', {
        product_name: productName
      })
    },
    isOk() {
      if (this.isSpecialAlert) {
        return false
      }
      return (
        this.daysUntilExpiration !== null &&
        this.daysUntilExpiration > this.subscriptionOkDays
      )
    },
    isNotice() {
      if (this.isSpecialAlert) {
        return false
      }
      return (
        this.daysUntilExpiration !== null &&
        !this.isOk &&
        this.daysUntilExpiration >= 0
      )
    },
    isAlert() {
      if (this.isSpecialAlert) {
        return true
      }

      return (
        this.daysUntilExpiration !== null &&
        !this.isNotice &&
        this.daysUntilExpiration < 0
      )
    },
    isSpecialAlert() {
      if (this.isContractOnHold) {
        return true
      }

      if (this.isPaymentFailing) {
        return true
      }

      if (this.customerIsSuspended) {
        return true
      }

      return false
    },
    isWaitingForPayment() {
      return this.isContractWaitingForPayment
    },
    isRefunded() {
      return false // TODO implement via api
    },
    isChargedBack() {
      return false // TODO implement via api
    },
    isPaymentNotReceived() {
      return false // TODO implement
    },
    isContentCentered() {
      return (
        this.isWaitingForPayment ||
        this.isRefunded ||
        this.isChargedBack ||
        this.isPaymentNotReceived
      )
    },
    showSecondParagraph() {
      return this.popupTextSecond || this.contentLinkText
    },
    showSecondButton() {
      return this.isNotice && !this.isSubUser
    },
    showFooterCouponLink() {
      return !this.isOk && this.inCouponRenewalPeriod && !this.isSpecialAlert
    },
    isFooterTextEmpty() {
      return this.footerText === ''
    },
    displayFooter() {
      return this.showFooterCouponLink || !this.isFooterTextEmpty
    },
    teaserText() {
      return XmlLocalizeService.getTranlationText.bind(this)(
        this.reminderPopupTeaser,
        this.currentLocale,
        {}
      )
    }
  },
  mounted() {
    this.$root.$on('purchase-occured', this.handleShowingOnPurchase)
    this.$root.$on('just-logged-in', this.handleShowingOnLogin)

    if (this.$props.evaluateShowOnMount) {
      this.handleShowingOnLogin()
    }
  },
  beforeDestroy() {
    this.$root.$off('purchase-occured', this.handleShowingOnPurchase)
    this.$root.$off('just-logged-in', this.handleShowingOnLogin)
  },
  methods: {
    closeOrNavigate(event) {
      if (this.buttonUrl === '#') {
        event.preventDefault()
        this.closePopup()
        return
      }

      const newLocation = this.buttonUrl && this.$router.resolve(this.buttonUrl)

      if (newLocation.resolved.name !== '404') {
        event.preventDefault()
        this.$router.push(newLocation.resolved.path)
        this.closePopup()
        return
      }

      if (this.clicked) {
        return
      }

      this.clicked = true

      window.location = this.$refs['purchase-button'].href
    },
    handleShowingOnLogin() {
      if (
        this.daysUntilExpiration === null ||
        this.isOk ||
        (this.isNotice && this.isAutomaticSubscription)
      ) {
        return
      }

      // this is to exclude nagging for all trials with less than a month's subscription except P1D
      // for eg. we offer 5 and 14 days trial which will start nagging immediately due to our logic to save billing_nextreminderdate
      let licensePeriodExclusion = ['P5D', 'P14D']
      if (this.isTrial && licensePeriodExclusion.includes(this.licensePeriod) && this.daysUntilExpiration > 3) {
        return false
      }

      this.isOpen = true
    },
    handleShowingOnPurchase(purchaseType) {
      if (
        this.isSubUser ||
        purchaseType === 'upgrade' ||
        purchaseType === 'buy-more'
      ) {
        return
      }

      this.isOpen = true
      this.$nextTick(() => {
        this.$root.$emit('toggle_popup', {
          elementId: 'top-menu',
          action: 'close'
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-popup.wide /deep/ {
  .footer {
    p:first-child {
      max-width: 400px;
      margin: 0 auto;
    }

    p + .flex-btn {
      margin-top: 20px;
    }

    &.more-padding {
      padding: 50px 20px;

      p + .flex-btn {
        margin-top: 40px;
      }
    }
  }

  .box {
    max-width: 640px;
    flex: auto;
    width: 100%;

    .popup-content {
      padding-bottom: 45px;
    }
  }
}

.notice {
  h3 {
    color: $warning-color;
  }
}

.alert {
  h3 {
    color: $danger-color;
  }
}

.ok {
  h3 {
    color: $ok-color;
  }
}

h2 {
  margin-bottom: 36px;
}

.flex-btn + .flex-btn {
  margin-top: 10px;
}

h3 {
  font-size: 1.28571rem;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0.288em;
}

p {
  margin: 0;
  color: #666;
  line-height: 1.5rem;

  &.teaser {
    background-color: #f5f5f5;
    display: inline-block;
    font-style: italic;
    font-weight: normal;
    line-height: 33px;
    margin-top: 25px;
    padding: 0 10px;
  }
}

.content-row {
  display: flex;
  text-align: left;
  align-items: center;
  margin: 0 auto 40px auto;
  max-width: 485px;

  p:first-child + p {
    margin-top: 36px;
  }

  &.centered {
    justify-content: center;
    text-align: center;
  }

  &.valign-to-top {
    align-items: flex-start;
  }

  &.no-bottom-padding {
    margin-bottom: 0;
  }
}

.icon {
  width: 44px;

  + .text {
    padding-left: 20px;
    width: 100%;
  }

  img {
    vertical-align: top;
  }
}

@media (min-width: $desktop-min-width) {
  .modal-popup.wide /deep/ {
    .box {
      .popup-content {
        padding-bottom: 50px;
      }
    }
  }
  .modal-popup.ok /deep/ {
    .box {
      .popup-content {
        padding-bottom: 55px;
      }
    }
  }
  h3 {
    font-size: 1.71429rem;
  }
  .icon {
    width: 64px;
  }
  .flex-btn {
    width: auto;

    + .flex-btn {
      margin-left: 10px;
    }
  }
}
</style>
