var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.upgradeOverridden
    ? _c(
        "div",
        {
          staticClass: "upgrade-popup-content",
          class: { reverse: _vm.isSafeFirst }
        },
        [
          _c("h2", [_vm._v(_vm._s(_vm.popupTitle))]),
          _c("div", { staticClass: "choices" }, [
            _c(
              "a",
              {
                staticClass: "choice-item",
                class: { selected: _vm.selectedItem === 1 },
                attrs: { href: "#", id: "upgrade-popup-total-choice" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.select(1)
                  }
                }
              },
              [
                _c("div", { staticClass: "header" }, [
                  _vm._m(0),
                  _c("div", { staticClass: "text" }, [
                    _c("h3", [_vm._v(_vm._s(_vm.upgradeTotalSubtitle))])
                  ])
                ]),
                _c("div", { staticClass: "content" }, [
                  _vm._m(1),
                  _c("div", { staticClass: "text" }, [
                    _c("p", [_vm._v(_vm._s(_vm.upgradeTotalDescription))]),
                    _c("ul", [
                      _c("li", [
                        _vm._v(_vm._s(_vm.upgradeTotalDescriptionPoint1))
                      ]),
                      _c("li", [
                        _vm._v(_vm._s(_vm.upgradeTotalDescriptionPoint2))
                      ]),
                      _c("li", [
                        _vm._v(_vm._s(_vm.upgradeTotalDescriptionPoint3))
                      ])
                    ])
                  ]),
                  _vm.baseProductPrice
                    ? _c("div", { staticClass: "price" }, [
                        _c("p", { staticClass: "device-count" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.totalDevicesText) +
                              "\n          "
                          )
                        ]),
                        _c("p", { staticClass: "value" }, [
                          _vm._v(_vm._s(_vm.totalPrice))
                        ]),
                        _vm.isBuyMoreTypeCart
                          ? _c("p", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t(
                                      "upgrade.total_upgrade_text_duration"
                                    )
                                  ) +
                                  "\n          "
                              )
                            ])
                          : _vm._e()
                      ])
                    : _vm._e()
                ])
              ]
            ),
            _c(
              "a",
              {
                staticClass: "choice-item",
                class: { selected: _vm.selectedItem === 2 },
                attrs: { href: "#", id: "upgrade-popup-safe-choice" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.select(2)
                  }
                }
              },
              [
                _c("div", { staticClass: "header" }, [
                  _vm._m(2),
                  _c("div", { staticClass: "text" }, [
                    _c("h3", [_vm._v(_vm._s(_vm.baseProductTitle))])
                  ])
                ]),
                _c("div", { staticClass: "content" }, [
                  _c(
                    "div",
                    { staticClass: "image" },
                    [
                      _vm.isIDPSubscription
                        ? [
                            _c("img", {
                              staticStyle: { margin: "10px" },
                              attrs: {
                                src: require("../assets/images/idp.png"),
                                alt: ""
                              }
                            })
                          ]
                        : _vm.isFreedomeSubscription
                        ? [
                            _c("img", {
                              staticStyle: { margin: "10px" },
                              attrs: {
                                src: require("../assets/images/freedome.png"),
                                alt: ""
                              }
                            })
                          ]
                        : [
                            _c("img", {
                              staticStyle: { margin: "10px" },
                              attrs: {
                                src: require("../assets/images/safe-new.png"),
                                alt: ""
                              }
                            })
                          ]
                    ],
                    2
                  ),
                  _c("div", { staticClass: "text" }, [
                    _c("p", [_vm._v(_vm._s(_vm.baseProductText))])
                  ]),
                  _vm.baseProductPrice
                    ? _c("div", { staticClass: "price" }, [
                        _c("p", { staticClass: "device-count" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.baseProductDevicesText) +
                              "\n          "
                          )
                        ]),
                        _c("p", { staticClass: "value" }, [
                          _vm._v(_vm._s(_vm.baseProductPrice))
                        ]),
                        _vm.isBuyMoreTypeCart
                          ? _c("p", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t(
                                      "upgrade.total_upgrade_text_duration"
                                    )
                                  ) +
                                  "\n          "
                              )
                            ])
                          : _vm._e()
                      ])
                    : _vm._e()
                ])
              ]
            )
          ]),
          _c(
            "a",
            {
              staticClass: "flex-btn primary",
              attrs: { id: "upgrade-popup-buy-button", href: _vm.goToUrl },
              nativeOn: {
                click: function($event) {
                  return _vm.doNothingIfClicked($event)
                }
              }
            },
            [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("upgrade.btn_continue_to_checkout")))
              ])
            ]
          ),
          _c("p", { staticClass: "bottom-note" }, [
            _vm._v(_vm._s(_vm.$t("upgrade.best_protection_award_hint")))
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "radio-button" }, [
      _c("img", {
        attrs: {
          src: require("../assets/images/icons/radiobutton_off.svg"),
          alt: "off"
        }
      }),
      _c("img", {
        attrs: {
          src: require("../assets/images/icons/radiobutton_on.svg"),
          alt: "on"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "image" }, [
      _c("img", {
        staticStyle: { margin: "10px" },
        attrs: { src: require("../assets/images/total-new.png"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "radio-button" }, [
      _c("img", {
        attrs: {
          src: require("../assets/images/icons/radiobutton_off.svg"),
          alt: "off"
        }
      }),
      _c("img", {
        attrs: {
          src: require("../assets/images/icons/radiobutton_on.svg"),
          alt: "on"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }