var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "item" }, [
    _c("div", { staticClass: "title" }, [
      _vm._v("\n    " + _vm._s(_vm.$t("subscription.hl_date")) + "\n  ")
    ]),
    _c("div", { staticClass: "title" }, [
      _vm._v("\n    " + _vm._s(_vm.$t("subscription.hl_service")) + "\n  ")
    ]),
    _c("div", { staticClass: "title" }, [
      _vm._v("\n    " + _vm._s(_vm.$t("subscription.hl_paid_by")) + "\n  ")
    ]),
    _c("div", { staticClass: "title" }, [
      _vm._v("\n    " + _vm._s(_vm.$t("subscription.hl_total")) + "\n  ")
    ]),
    _c("div", { staticClass: "title" }, [
      _vm._v("\n    " + _vm._s(_vm.$t("subscription.hl_documents")) + "\n  ")
    ]),
    _c("div", { staticClass: "value" }, [
      _vm._v("\n    " + _vm._s(_vm.formattedDate) + "\n  ")
    ]),
    _c("div", { staticClass: "value" }, [
      _vm._v("\n    " + _vm._s(_vm.translatedService) + "\n  ")
    ]),
    _c("div", { staticClass: "value" }, [
      _vm._v("\n    " + _vm._s(_vm.translatedPaymentMethod) + "\n  ")
    ]),
    _c("div", { staticClass: "value" }, [
      _vm._v("\n    " + _vm._s(_vm.priceAndCurrency) + "\n  ")
    ]),
    _c("div", { staticClass: "value" }, [
      _c(
        "a",
        {
          attrs: {
            "data-track-event": "download",
            "data-track-name": "open-invoice",
            target: "_blank",
            rel: "noopener noreferrer",
            href: _vm.item.invoiceLink
          }
        },
        [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.$t("subscription.lnk_open_document", {
                  purchase_document_title: _vm
                    .$t("subscription.purchase_document_title_inv")
                    .toLowerCase()
                })
              ) +
              "\n    "
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }