<template>
  <div class="popup-content" :class="{ 'more-padding': morePadding }">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    morePadding: {
      type: Boolean
    }
  }
}
</script>

<style lang="scss" scoped>
.popup-content {
  &.more-padding {
    padding: 32px 20px 20px 20px;

    /deep/ a.close {
      top: 12px;
      right: 12px;
    }
  }
}

.popup-content /deep/ {
  padding: 20px;
  text-align: center;

  h2 {
    color: $primary-color;
    font-size: 2em;
    line-height: 1.21886em;
    margin-top: 0;
    font-weight: 600;
  }

  // input[type='text'] {
  //   border-radius: 0;
  //   width: 100%;
  //   min-height: 42px;
  //   border: 1px solid #dedede;
  //   color: #666;
  //   padding: 0 10px;
  // }

  // button {
  //   color: #fff;
  //   background: $primary-color;
  //   border: 1px solid $primary-color;
  //   font-weight: 700;
  //   font-weight: bold;
  //   font-size: 18px;
  //   font-family: 'FSecureOffice';
  //   padding: 0 18px;
  //   height: 100%;
  //   display: inline-block;
  //   min-height: 40px;
  //   border-radius: 4px;
  //   margin-top: 8px;
  //   width: 100%;
  // }
}
@media (min-width: $desktop-min-width) {
  .popup-content {
    &.more-padding {
      padding: 42px 54px 20px 54px;

      /deep/ a.close {
        top: 20px;
        right: 20px;
      }
    }
  }
  .popup-content /deep/ {
    h2 {
      font-size: 2.57143rem;
    }
  }
}
</style>
