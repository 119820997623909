var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view-content" }, [
    _vm.couponValid
      ? _c("div", { staticClass: "apply-coupon" }, [_vm._t("apply-coupon")], 2)
      : _c("div", { staticClass: "enter-coupon" }, [_vm._t("enter-coupon")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }