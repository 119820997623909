<template>
  <header class="the-header-bar-announcement">
    <div v-if="showVpnAnnouncement">
      <span>{{
        $t('dashboard.announcement_for_migrated_vpn_standalone')
      }}</span>
    </div>
    <div v-if="showIdpAnnouncement">
      <span
        >{{ $t('dashboard.announcement_for_migrated_idp_standalone') }}
      </span>
    </div>
  </header>
</template>
<script>
export default {
  data() {
    return {
      resizing: false,
      isActive: false
    }
  },
  computed: {
    showVpnAnnouncement() {
      return this.$store.state.customer.licenses.freedomeTotalLicenses
    },
    showIdpAnnouncement() {
      return this.$store.state.customer.licenses.keyTotalLicenses
    }
  },
  methods: {
    sendHeightChanged() {
      if (!this.isActive) {
        return
      }
      this.$nextTick(() => {
        if (!this.isActive) {
          return
        }
        this.$root.$emit(
          'header-height-changed',
          this.$el.getBoundingClientRect().height
        )
        this.resizing = false
      })
    },
    handleResize: function() {
      if (this.resizing) {
        return
      }

      this.resizing = true

      if (window.requestAnimationFrame) {
        window.requestAnimationFrame(this.sendHeightChanged)
      } else {
        window.setTimeout(this.sendHeightChanged, 66)
      }
    }
  },
  activated() {
    this.isActive = true
    this.sendHeightChanged()
    window.addEventListener('resize', this.handleResize)
  },
  deactivated() {
    this.isActive = false
    window.removeEventListener('resize', this.handleResize)
  },
  updated() {
    this.sendHeightChanged()
  }
}
</script>
<style lang="scss" scoped>
body:not(.top-menu-open) .the-header-bar-announcement.no-shadow {
  box-shadow: none;
}

.the-header-bar-announcement {
  background: #fff;
  font-family: $default-fonts;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  z-index: 3;
  position: fixed;
  width: 100%;
  top: 125px;

  div {
    text-align: center;
  }

  span {
    color: red;
    font-weight: bold;
  }
}

@media (min-width: $desktop-min-width) {
  .the-header-bar-announcement {
    top: 135px;
    &.nav-shown {
      > section:first-child {
        /deep/ picture {
          display: flex;
        }
      }
    }

    > section {
      min-height: 50px;
      max-width: 960px;
      margin: 0 auto;
    }
  }
}

@media (min-width: $desktop-min-width) and (max-width: 991px) {
  .the-header-bar-announcement > section {
    margin: 0 16px;
  }
}
</style>
