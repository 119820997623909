<template>
  <one-col-box
    v-bind="$props"
    :class="{ 'less-close-padding': lessClosePadding }"
  >
    <router-link class="close" v-if="closeButtonUrl" :to="closeButtonUrl">
      <span>
        <picture>
          <img src="../assets/images/icons/icon_close.svg" alt="close" />
          <img src="../assets/images/icons/icon_close_hover.svg" alt="close" />
        </picture>
      </span>
    </router-link>
    <slot />
  </one-col-box>
</template>

<script>
import OneColBox from './OneColBox.vue'
export default {
  props: {
    startHigher: {
      type: Boolean
    },
    noTransform: {
      type: Boolean
    },
    applyTopMargin: {
      type: Boolean
    },
    addSpaceToTop: {
      type: Boolean
    },
    roundedCorners: {
      type: Boolean
    },
    noPadding: {
      type: Boolean
    },
    noBackground: {
      type: Boolean
    },
    closeButtonUrl: {
      type: String
    },
    lessClosePadding: {
      type: Boolean
    }
  },
  components: {
    OneColBox
  }
}
</script>

<style lang="scss" scoped>
.one-col-box {
  &.less-close-padding {
    a.close {
      top: 12px;
      right: 12px;
    }
  }
}
.one-col-box /deep/ {
  margin-left: 20px;
  margin-right: 20px;

  .content {
    position: relative;
  }
}

a.close {
  display: block;
  width: 26px;
  height: 26px;
  position: absolute;
  top: 20px;
  right: 20px;

  span {
    display: block;
  }

  picture {
    display: block;
  }

  img {
    vertical-align: middle;
    display: inline-block;

    &:last-child {
      display: none;
    }
  }

  &:hover {
    img {
      &:first-child {
        display: none;
      }

      &:last-child {
        display: inline-block;
      }
    }
  }
}
@media (min-width: $desktop-min-width) {
  .one-col-box {
    &.less-close-padding {
      a.close {
        top: 20px;
        right: 20px;
      }
    }
  }

  .one-col-box /deep/ {
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
