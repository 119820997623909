<template>
  <div class="item">
    <div class="title">
      {{ $t('subscription.hl_date') }}
    </div>
    <div class="title">
      {{ $t('subscription.hl_service') }}
    </div>
    <div class="title">
      {{ $t('subscription.hl_paid_by') }}
    </div>
    <div class="title">
      {{ $t('subscription.hl_total') }}
    </div>
    <div class="title">
      {{ $t('subscription.hl_documents') }}
    </div>
    <div class="value">
      {{ formattedDate }}
    </div>
    <div class="value">
      {{ translatedService }}
    </div>
    <div class="value">
      {{ translatedPaymentMethod }}
    </div>
    <div class="value">
      {{ priceAndCurrency }}
    </div>
    <div class="value">
      <a
        data-track-event="download"
        data-track-name="open-invoice"
        target="_blank"
        rel="noopener noreferrer"
        :href="item.invoiceLink"
      >
        {{
          $t('subscription.lnk_open_document', {
            purchase_document_title: $t(
              'subscription.purchase_document_title_inv'
            ).toLowerCase()
          })
        }}
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      unifiedProductNames: 'ui/unifiedProductNames'
    }),
    formattedDate() {
      let tmpDate = new Date(
        this.$props.item.date.replace('.000000', '').replace(' ', 'T')
      )

      if (!tmpDate) {
        return ''
      }

      let options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      }

      let currentLocale = this.$store.state.ui.currentLocale
        ? this.$store.state.ui.currentLocale
        : 'en_US'

      let currentLocaleAltered = currentLocale.replace('_', '-')

      return typeof tmpDate === 'object'
        ? tmpDate.toLocaleDateString(currentLocaleAltered, options)
        : ''
    },
    translatedPaymentMethod() {
      let returnValue = this.$t(
        'subscription.' + this.$props.item.paymentMethod
      )
      if (returnValue.startsWith('subscription.')) {
        return ''
      }
      return returnValue
    },
    translatedService() {
      let service = this.$props.item.service
      if (service === 'total_epp') {
        return this.unifiedProductNames.epp
      } else if (service === 'total_idp') {
        return this.unifiedProductNames.idp
      } else if (service === 'total_vpn') {
        return this.unifiedProductNames.vpn
      } else if (service === 'total_epp_idp') {
        return this.unifiedProductNames.eppIdp
      } else if (service === 'total_epp_vpn') {
        return this.unifiedProductNames.eppVpn
      } else if (service === 'total_vpn_idp') {
        return this.unifiedProductNames.vpnIdp
      } else if (
        service === 'total_standard' ||
        service === 'total_standard_with_sense'
      ) {
        return this.unifiedProductNames.total
      } else if (service === 'total_standard_id_monitoring_only') {
        return this.unifiedProductNames.totalIdMonitoring
      }
      return service
    },
    priceAndCurrency() {
      return (
        parseFloat(this.$props.item.price).toFixed(2) +
        ' ' +
        this.$props.item.currency
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.item {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  + .item {
    border-top: 1px solid #dedede;
    margin-top: 10px;
    padding-top: 10px;
  }
}

.title {
  color: #666;
  font-weight: 600;
  font-size: 1.14286rem;
  grid-column: 1;
}

.value {
  color: $font-color;
  font-weight: 300;
  font-size: 1.14286rem;
  grid-column: 2;
  text-align: right;

  &:nth-child(6) {
    grid-row: 1;
  }

  &:nth-child(7) {
    grid-row: 2;
  }

  &:nth-child(8) {
    grid-row: 3;
  }

  &:nth-child(9) {
    grid-row: 4;
  }

  &:nth-child(10) {
    grid-row: 5;
  }
}

@media (min-width: $desktop-min-width) {
  .item {
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 10px;

    + .item {
      border-top: none;
      margin-top: 16px;
      padding-top: 0;
    }

    &:not(:first-child) .title {
      display: none;
    }

    .title {
      grid-column: auto;
    }

    .value {
      grid-column: auto;
      grid-row: auto;
      text-align: left;
    }
  }
}

@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  .item {
    display: -ms-grid;
    -ms-grid-columns: 1fr 1fr;

    .title {
      &:nth-child(1) {
        -ms-grid-column: 1;
        -ms-grid-row: 1;
      }
      &:nth-child(2) {
        -ms-grid-column: 1;
        -ms-grid-row: 2;
      }
      &:nth-child(3) {
        -ms-grid-column: 1;
        -ms-grid-row: 3;
      }
      &:nth-child(4) {
        -ms-grid-column: 1;
        -ms-grid-row: 4;
      }
      &:nth-child(5) {
        -ms-grid-column: 1;
        -ms-grid-row: 5;
      }
    }

    .value {
      &:nth-child(6) {
        -ms-grid-column: 2;
        -ms-grid-row: 1;
      }
      &:nth-child(7) {
        -ms-grid-column: 2;
        -ms-grid-row: 2;
      }
      &:nth-child(8) {
        -ms-grid-column: 2;
        -ms-grid-row: 3;
      }
      &:nth-child(9) {
        -ms-grid-column: 2;
        -ms-grid-row: 4;
      }
      &:nth-child(10) {
        -ms-grid-column: 2;
        -ms-grid-row: 5;
      }
    }
  }
}

@media screen and (-ms-high-contrast: active) and (min-width: $desktop-min-width),
  screen and (-ms-high-contrast: none) and (min-width: $desktop-min-width) {
  .item {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;

    .title {
      &:nth-child(1) {
        -ms-grid-column: 1;
        -ms-grid-row: 1;
      }
      &:nth-child(2) {
        -ms-grid-column: 2;
        -ms-grid-row: 1;
      }
      &:nth-child(3) {
        -ms-grid-column: 3;
        -ms-grid-row: 1;
      }
      &:nth-child(4) {
        -ms-grid-column: 4;
        -ms-grid-row: 1;
      }
      &:nth-child(5) {
        -ms-grid-column: 5;
        -ms-grid-row: 1;
      }
    }

    .value {
      &:nth-child(6) {
        -ms-grid-column: 1;
        -ms-grid-row: 2;
      }
      &:nth-child(7) {
        -ms-grid-column: 2;
        -ms-grid-row: 2;
      }
      &:nth-child(8) {
        -ms-grid-column: 3;
        -ms-grid-row: 2;
      }
      &:nth-child(9) {
        -ms-grid-column: 4;
        -ms-grid-row: 2;
      }
      &:nth-child(10) {
        -ms-grid-column: 5;
        -ms-grid-row: 2;
      }
    }
  }
}
</style>
