<template>
  <div class="status-info">
    <span class="icon">
      <img :src="iconUrl" alt />
    </span>
    <span class="message">
      {{ message }}
      <router-link v-if="linkUrl" :to="linkUrl">{{ linkText }}</router-link>
    </span>
  </div>
</template>
<script>
export default {
  props: {
    type: String,
    message: String,
    linkUrl: String,
    linkText: String
  },
  computed: {
    iconUrl: function() {
      let iconUrl = ''
      if (this.type === 'ok') {
        iconUrl = require('../assets/images/icons/icon_status_green_fill_small.svg')
      } else if (this.type === 'notice') {
        iconUrl = require('../assets/images/icons/icon_status_yellow_fill_small.svg')
      } else if (this.type === 'alert') {
        iconUrl = require('../assets/images/icons/icon_status_red_fill_small.svg')
      }

      return iconUrl
    }
  }
}
</script>
