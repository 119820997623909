<template>
  <div class="settings-content">
    <slot />
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.settings-content {
  padding: 40px;

  + .settings-content {
    border-top: 1px solid #f2f2f2;
  }

  /deep/ p {
    color: #666;
    font-size: 1.14286rem;
  }

  &:last-child {
    margin-bottom: 5px;
  }

  > h2 {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 1.429rem;
    font-weight: 600;
    line-height: 1.21886em;
  }
}
</style>
