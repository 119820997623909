var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isVisible
    ? _c(
        "base-settings-content-section",
        { attrs: { id: "additional-services-id" } },
        [
          _c("h2", { attrs: { id: "hl-additional-services" } }, [
            _vm._v(
              "\n    " +
                _vm._s(_vm.$t("subscription.hl_additional_services")) +
                "\n  "
            )
          ]),
          _c(
            "div",
            { staticClass: "grid" },
            _vm._l(_vm.filteredAdditionalServices, function(service) {
              return _c("service-item", {
                key: service.id,
                attrs: { "service-id": service.id }
              })
            }),
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }