<template>
  <base-view is-blue-bg-view>
    <template #top>
      <the-header is-nav-shown :no-shadow="showStatusBar" />
      <the-statusbar v-if="showStatusBar" />
      <the-header-bar-announcement v-if="showAnnouncementMigratedStandaloneIdpVpn" />
      <avenue-iframe />
    </template>
    <template #bottom>
      <the-region-picker />
      <the-help />
      <the-footer />
    </template>
  </base-view>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import TheHeader from '@/components/TheHeader.vue'
import TheStatusbar from '@/components/TheStatusbar.vue'
import TheHeaderBarAnnouncement from '@/components/TheHeaderBarAnnouncement.vue'
import TheHelp from '@/components/TheHelp.vue'
import TheRegionPicker from '@/components/TheRegionPicker.vue'
import TheFooter from '@/components/TheFooter.vue'
import AvenueIframe from '@/components/AvenueIframe.vue'
import BaseView from '@/components/BaseView.vue'

export default {
  components: {
    TheHeader,
    TheStatusbar,
    TheHeaderBarAnnouncement,
    AvenueIframe,
    TheRegionPicker,
    TheHelp,
    TheFooter,
    BaseView
  },
  computed: {
    ...mapState({
      appDataLoaded: state => state.customer.remoteDataLoaded,
      customerIsLoggedIn: state => state.customer.isLoggedIn,
      iframeUrl: state => state.customer.safeAvenueIframeUrl,
      expirationDate: state => state.customer.contract.expirationDate,
      daysUntilExpiration: state => state.customer.contract.daysUntilExpiration
    }),
    ...mapGetters({
      customerIsIncomplete: 'customer/isIncomplete',
      isSubUser: 'customer/contract/isSubUser',
      isMigratedStandaloneIdpVpn: 'customer/licenses/isMigratedStandaloneIdpVpn'
    }),
    customerIsNotIncompleteAndLoggedIn() {
      return (
        this.customerIsLoggedIn &&
        this.appDataLoaded &&
        !this.customerIsIncomplete
      )
    },
    showStatusBar() {
      return (
        this.customerIsNotIncompleteAndLoggedIn &&
        (!this.isSubUser ||
          (this.isSubUser &&
            this.expirationDate !== null &&
            this.daysUntilExpiration !== null))
      )
    },
    showAnnouncementMigratedStandaloneIdpVpn() {
      return this.customerIsLoggedIn && this.isMigratedStandaloneIdpVpn
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$root.$emit('route-entered', to.path, from.path)
      vm.$root.$emit('product-view-entered', to.path, from.path)
    })
  },
  beforeRouteUpdate(to, from, next) {
    this.$root.$emit('product-view-route-updated', to.path, from.path)
    next()
  },
  beforeRouteLeave(to, from, next) {
    this.$root.$emit('product-view-route-left', to.path, from.path)
    next()
  }
}
</script>
