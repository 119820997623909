<template>
  <base-view is-blue-bg-view overlayed>
    <template #top>
      <the-header is-nav-shown />
      <one-col-box-with-close
        no-transform
        add-space-to-top
        apply-top-margin
        close-button-url="/portal/complete-registration"
      >
        <the-start-trial-content />
      </one-col-box-with-close>
    </template>
    <template #bottom>
      <the-region-picker />
      <the-help />
      <the-footer />
    </template>
  </base-view>
</template>
<script>
import TheHeader from '@/components/TheHeader.vue'
import TheHelp from '@/components/TheHelp.vue'
import TheRegionPicker from '@/components/TheRegionPicker.vue'
import TheFooter from '@/components/TheFooter.vue'
import BaseView from '@/components/BaseView.vue'
import TheStartTrialContent from '@/components/TheStartTrialContent.vue'
import OneColBoxWithClose from '@/components/OneColBoxWithClose.vue'

export default {
  components: {
    OneColBoxWithClose,
    TheHeader,
    TheRegionPicker,
    TheHelp,
    TheFooter,
    BaseView,
    TheStartTrialContent
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$root.$emit('route-entered', to.path, from.path)
      vm.$root.$emit('start-trial-view-entered', to.path, from.path)
    })
  }
}
</script>

<style lang="scss" scoped>
/deep/ .one-col-box {
  min-height: 445px;

  .content {
    padding: 20px;
  }
}

@media (min-width: $desktop-min-width) {
  /deep/ .one-col-box {
    max-width: 640px;

    .content {
      padding: 48px 60px 40px 60px;
    }
  }
}
</style>
