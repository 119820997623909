var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "input-wrapper", class: { loading: _vm.loading } },
    [
      _vm.label
        ? _c("label", { attrs: { for: _vm.$attrs.id } }, [
            _c("span", [_vm._v(_vm._s(_vm.label))])
          ])
        : _vm._e(),
      _c(
        "base-input",
        _vm._g(
          _vm._b(
            { ref: "input", attrs: { value: _vm.value } },
            "base-input",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }