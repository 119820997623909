<template>
  <base-view is-blue-bg-view overlayed>
    <template #top>
      <the-header is-nav-shown />
      <one-col-box-with-close
        no-transform
        add-space-to-top
        apply-top-margin
        close-button-url="/portal/contact"
      >
        <the-account-update-view-content />
      </one-col-box-with-close>
    </template>
    <template #bottom>
      <the-region-picker />
      <the-help />
      <the-footer />
    </template>
  </base-view>
</template>
<script>
import TheHeader from '@/components/TheHeader.vue'
import TheHelp from '@/components/TheHelp.vue'
import TheRegionPicker from '@/components/TheRegionPicker.vue'
import TheFooter from '@/components/TheFooter.vue'
import BaseView from '@/components/BaseView.vue'
import OneColBoxWithClose from '@/components/OneColBoxWithClose.vue'
import TheAccountUpdateViewContent from '@/components/TheAccountUpdateViewContent.vue'

export default {
  components: {
    TheHeader,
    TheRegionPicker,
    TheHelp,
    TheFooter,
    BaseView,
    OneColBoxWithClose,
    TheAccountUpdateViewContent
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$root.$emit('route-entered', to.path, from.path)
      vm.$root.$emit('account-update-view-entered', to.path, from.path)
    })
  }
}
</script>

<style lang="scss" scoped>
/deep/ .one-col-box .content {
  padding: 20px;
}
@media (max-width: 520px) {
  /deep/ .one-col-box .content {
    padding: 0 !important;
  }
  a.close {
    top: 20px;
    right: 20px;
  }
}
</style>
