<template>
  <one-col-box-grid-content-box>
    <template v-if="showSuccess">
      <h2>
        {{ $t('convert_to_annual.hl_conversion_success') }}
      </h2>
      <p>
        <span class="icon">
          <img
            src="../assets/images/icons/icon_status_green_fill_small.svg"
            alt
          />
        </span>
        {{
          $t('convert_to_annual.conversion_success_desc', {
            currency: billingCurrency,
            nextBillingDate: nextBillingDate,
            amount: billingAmount
          })
        }}
      </p>
      <base-button-link
        class="primary"
        to="/portal/order"
        @click.native="resetData"
      >
        {{ $t('convert_to_annual.btn_conversion_success_back') }}
      </base-button-link>
    </template>
    <template v-else>
      <h2>
        {{ $t('convert_to_annual.hl_convert_to_annual') }}
      </h2>
      <p>
        {{ $t('convert_to_annual.hl_description') }}
      </p>
      <base-button-link
        class="primary"
        prevent-click
        to="/portal/order"
        @click.native="convertToAnnual"
        :class="{ loading }"
        data-track-event="navigate"
        data-track-name="confirmed-monthly-to-annual"
      >
        {{ $t('convert_to_annual.btn_confirm') }}
      </base-button-link>
      <base-button-link
        class="neutral"
        to="/portal/order"
        @click.native="resetData"
      >
        {{ $t('convert_to_annual.btn_cancel_back') }}
      </base-button-link>
      <p>
        {{
          $t('convert_to_annual.hl_description2', {
            nextBillingDate: nextBillingDate,
            currency: billingCurrency,
            amount: billingAmount
          })
        }}
      </p>
    </template>
  </one-col-box-grid-content-box>
</template>

<script>
import SubscriptionService from '@/services/SubscriptionService.js'
import { mapGetters } from 'vuex'
import OneColBoxGridContentBox from './OneColBoxGridContentBox.vue'
import BaseButtonLink from '@/components/BaseButtonLink.vue'
import DataMixin from '@/mixins/data'
export default {
  data() {
    return {
      requestSent: false,
      success: false,
      loading: false,
      nextBillingDate: null,
      billingAmount: null,
      billingCurrency: null
    }
  },
  mixins: [DataMixin],
  components: {
    OneColBoxGridContentBox,
    BaseButtonLink
  },
  mounted() {
    /* Don't use computed since the success page still
    need this value and it will be overridden by the next get_customer call */
    if (this.$store.state.customer.convertToAnnualOptions) {
      this.nextBillingDate = this.$store.state.customer.convertToAnnualOptions.nextBillingDate
      this.billingCurrency = this.$store.state.customer.convertToAnnualOptions.billingCurrency
      this.billingAmount = this.$store.state.customer.convertToAnnualOptions.billingPrice
    }
  },
  computed: {
    ...mapGetters({
      expirationDateFormatted: 'customer/contract/expirationDateFormatted'
    }),
    showSuccess() {
      return this.requestSent && this.success
    }
  },
  methods: {
    resetData() {
      this.requestSent = false
      this.success = false
    },
    async convertToAnnual() {
      if (this.loading) {
        return
      }

      this.loading = true
      const response = await this.preHandleResponse(() =>
        SubscriptionService.postConvertToAnnual(
          this.$store.state.customer.token
        )
      )
      this.loading = false

      if (!response || response.errorCode) {
        this.requestSent = true
        return
      }

      this.success = true
      this.requestSent = true
      this.$store.dispatch('customer/fetch')
      this.$store.dispatch('ui/fetchCart')
    }
  }
}
</script>

<style lang="scss" scoped>
.icon {
  img {
    width: 21px;
    margin-bottom: -6px;
  }
}
</style>
