<template>
  <router-link
    class="header-bar-link"
    v-bind="$attrs"
    v-on="$listeners"
    :event="$props.disabled ? '' : 'click'"
  >
    <slot />
  </router-link>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean
    }
  }
}
</script>

<style lang="scss" scoped>
@media (min-width: $desktop-min-width) {
  a {
    text-decoration: none;
    display: inline-block;
    line-height: 50px;
    font-size: 15px;
    color: $primary-color;

    &:visited {
      color: $primary-color;
    }

    + a {
      margin-left: 20px;
    }

    + .narrow-menu {
      margin-left: 20px;
      min-width: 162px;

      a {
        width: 100%;
      }
    }

    &.arrow-menu {
      &.menu-open {
        /deep/ img:first-child {
          display: inline-block;
        }

        /deep/ img:last-child {
          display: none;
        }
      }
    }

    &:hover {
      position: relative;

      &::before {
        content: '';
        display: block;
        position: absolute;
        background: $primary-color;
        width: 100%;
        height: 3px;
        bottom: 0;
      }
    }

    /deep/ .arrow-icon {
      width: 20px;
      height: 20px;
      float: right;
      margin-left: 5px;
      margin-top: -2px;

      img {
        max-width: 100%;
        max-height: 100%;
        vertical-align: middle;

        &:first-child {
          display: none;
        }
      }
    }
  }
}
</style>
