<template>
  <base-view ref="base-view">
    <template #top>
      <the-header is-nav-shown no-second-section />
      <the-title-section
        :title="$t('registration.hl_fsecure_service')"
        :subTitle="$t('registration.hl_protection_for_all_your_needs')"
      />
      <one-col-box no-padding :style="{ 'margin-bottom': marginBottom }">
        <the-down-for-maintenance-section />
        <avenue-iframe-background />
      </one-col-box>
    </template>
    <template #bottom>
      <the-region-picker />
      <the-help />
      <the-footer />
    </template>
  </base-view>
</template>
<script>
import AvenueIframeBackground from '@/components/AvenueIframeBackground.vue'
import TheHeader from '@/components/TheHeader.vue'
import TheHelp from '@/components/TheHelp.vue'
import TheRegionPicker from '@/components/TheRegionPicker.vue'
import TheFooter from '@/components/TheFooter.vue'
import BaseView from '@/components/BaseView.vue'
import TheTitleSection from '@/components/TheTitleSection.vue'
import TheDownForMaintenanceSection from '@/components/TheDownForMaintenanceSection.vue'
import OneColBox from '@/components/OneColBox.vue'

export default {
  data() {
    return {
      marginBottom: 0
    }
  },
  components: {
    TheHeader,
    TheTitleSection,
    TheDownForMaintenanceSection,
    TheRegionPicker,
    TheHelp,
    TheFooter,
    BaseView,
    OneColBox,
    AvenueIframeBackground
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$root.$emit('route-entered', to.path, from.path)
      vm.$root.$emit('subscription-view-entered', to.path, from.path)
    })
  },
  mounted() {
    this.$root.$on(
      'update-content-bottom-margin',
      this.updateContentBottomMargin
    )
  },
  beforeDestroy() {
    this.$root.$off(
      'update-content-bottom-margin',
      this.updateContentBottomMargin
    )
  },
  computed: {
    isSenseSubscription() {
      return this.$store.getters['customer/contract/isSenseSubscription']
    }
  },
  methods: {
    /**
     * Makes sure that dropdown content fits to page and bottom bar height is not increased
     */
    updateContentBottomMargin(data) {
      this.$nextTick(() => {
        let start = data.start
        let topSectionElem = this.$refs['base-view'].$refs['top']
        let topSectionHeight = topSectionElem.clientHeight
        let dropdownContentRef = this.$refs['dropdown-content']
        let dropdownContentElem = dropdownContentRef.$el.firstElementChild
        let dropdownStyle = window.getComputedStyle
          ? window.getComputedStyle(dropdownContentElem, null)
          : dropdownContentElem.currentStyle
        let marginTop = parseInt(dropdownStyle.marginTop) || 0
        let marginBottom = parseInt(dropdownStyle.marginBottom) || 0
        let dropDownContentHeight =
          dropdownContentElem.clientHeight + marginTop + marginBottom
        let spaceNeeded = dropDownContentHeight - (topSectionHeight - start)

        if (spaceNeeded > 0) {
          this.marginBottom = spaceNeeded + 'px'
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@media (min-width: $desktop-min-width) {
  .one-col-box /deep/ .content {
    .flex-btn {
      width: auto;
      min-width: 240px;

      + .flex-btn {
        margin-left: 10px;
      }
    }
  }
}
</style>
