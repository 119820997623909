var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal-popup",
      class: { wide: _vm.isWide, "less-close-padding": _vm.lessClosePadding },
      attrs: { "data-close-on-click": _vm.displayCloseButton }
    },
    [
      _c(
        "div",
        { staticClass: "box" },
        [
          _vm.displayCloseButton
            ? _c(
                "a",
                {
                  staticClass: "close",
                  attrs: { id: _vm.topCloseId, href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.onCloseClick($event)
                    }
                  }
                },
                [_vm._m(0)]
              )
            : _vm._e(),
          _c(
            "popup-content",
            { attrs: { "more-padding": _vm.morePadding } },
            [_vm._t("default")],
            2
          ),
          _vm.displayFooter
            ? _c(
                "div",
                {
                  staticClass: "footer",
                  class: { "more-padding": _vm.moreFooterPadding }
                },
                [_vm._t("footer")],
                2
              )
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("picture", [
        _c("img", {
          attrs: {
            src: require("../assets/images/icons/icon_close_x_gray.svg"),
            alt: "close"
          }
        }),
        _c("img", {
          attrs: {
            src: require("../assets/images/icons/icon_close_x_hover.svg"),
            alt: "close"
          }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }