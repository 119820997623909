<template>
  <base-modal-popup
    id="activation-instructions-popup"
    class="activation"
    v-show="isOpen"
    is-wide
    display-close-button
    :on-close-click="closeWithOptionalRefresh"
    more-padding
    less-close-padding
    top-close-id="activation-instructions-top-close"
  >
    <popup-content
      :close-popup="closeWithOptionalRefresh"
      :service-id="serviceId"
      :title="title"
      :list-title="listTitle"
      :list-subtitle="listSubtitle"
      :list-items="listItems"
      :footer-start-text="footerStartText"
      :footer-terms-link="footerTermsLink"
      :footer-text-in-between-links="footerTextInBetweenLinks"
      :footer-privacy-link="footerPrivacyLink"
      :footer-end-text="footerEndText"
      :terms-and-conditions-url="termsAndConditionsUrl"
      :privacy-policy-url="privacyPolicyUrl"
      :vouchers="vouchers"
    />
  </base-modal-popup>
</template>

<script>
import { mapState } from 'vuex'
import ActivationService from '@/services/ActivationService'
import XmlLocalizeService from '@/services/XmlLocalizeService'
import popupMixin from '@/mixins/popup'
import BaseModalPopup from './BaseModalPopup'
import PopupContent from './TheAdditionalServiceActivationInstructionsPopupContent'

export default {
  data() {
    return {
      refreshOnClose: false,
      serviceId: null,
      serviceNameShort: null,
      titleLocKey: null,
      serviceNameLocKey: null,
      listTitleLocKey: null,
      listSubtitleLocKey: null,
      footerLocKey: null,
      footerMessage: null,
      listItemsLocKeys: [],
      linksToGo: {},
      phones: {},
      hours: {},
      emails: {},
      termsAndConditionsUrls: {},
      privacyPolicyUrls: {},
      vouchers: []
    }
  },
  props: {
    closeByEsc: {
      type: Boolean,
      default: true
    }
  },
  mixins: [popupMixin],
  components: {
    BaseModalPopup,
    PopupContent
  },
  computed: {
    ...mapState({
      customerGeoRegion: state => state.customer.geoRegion,
      countryCode: state => state.customer.countryCode,
      currentLocale: state => state.ui.currentLocale
    }),
    serviceName() {
      return this.$t(this.serviceNameLocKey)
    },
    title() {
      return this.$t(this.titleLocKey, { servicename: this.serviceName })
    },
    listTitle() {
      return this.$t(this.listTitleLocKey, { website: this.serviceName })
    },
    listSubtitle() {
      return this.$t(this.listSubtitleLocKey)
    },
    footerStartText() {
      this.updateFooterText()
      const exec = /(.*?)\{termsstart\}/g.exec(this.footerMessage)
      return exec ? exec[1] : ''
    },
    footerTermsLink() {
      const exec = /\{termsstart\}(.*?)\{termsend\}/g.exec(this.footerMessage)
      return exec ? exec[1] : ''
    },
    footerTextInBetweenLinks() {
      const exec = /\{termsend\}(.*?)\{privacystart\}/g.exec(this.footerMessage)
      return exec ? exec[1] : ''
    },
    footerPrivacyLink() {
      const exec = /\{privacystart\}(.*?)\{privacyend\}/g.exec(
        this.footerMessage
      )
      return exec ? exec[1] : ''
    },
    footerEndText() {
      const exec = /\{privacyend\}(.*?)$/g.exec(this.footerMessage)
      return exec ? exec[1] : ''
    },
    listItems() {
      let localizedItems = []
      for (const itemLocKeyEntry of Object.entries(this.listItemsLocKeys)) {
        const itemLocKey = itemLocKeyEntry[1]
        localizedItems.push(
          this.$t(itemLocKey, {
            phone: this.phoneTagged,
            hour: this.hourTagged,
            email: this.emailTagged
          })
        )
      }

      return localizedItems
    },
    linkToGo() {
      const geoRegionLowered = this.customerGeoRegion.toLowerCase()
      if (this.linksToGo.hasOwnProperty(geoRegionLowered)) {
        return this.linksToGo[geoRegionLowered]
      }

      return this.linksToGo['default']
    },
    linkToGoTagged() {
      return `<a data-track-event="navigate" data-track-name="${this.serviceNameShort.toLowerCase()}" target="_blank" rel="noopener noreferrer" href="${
        this.linkToGo
      }">${this.linkToGo.match(/https:\/\/(.*)\//)[1]}</a>`
    },
    phoneAndEmail() {
      let phoneAndEmail = {
        phone: '',
        email: '',
        hour: ''
      }
      const countryCode = this.countryCode.toLowerCase()
      if (this.phones.hasOwnProperty(countryCode)) {
        phoneAndEmail.phone = this.phones[countryCode]
      } else {
        phoneAndEmail.phone = this.phones['default']
      }
      if (this.hours.hasOwnProperty(countryCode)) {
        phoneAndEmail.hour = this.hours[countryCode]
      } else {
        phoneAndEmail.hour = this.hours['default']
      }
      if (this.emails.hasOwnProperty(countryCode)) {
        phoneAndEmail.email = this.emails[countryCode]
      } else {
        phoneAndEmail.email = this.emails['default']
      }
      return phoneAndEmail
    },
    phoneTagged() {
      return `<a>${this.phoneAndEmail.phone}</a>`
    },
    hourTagged() {
      return `<a>${this.phoneAndEmail.hour}</a>`
    },
    emailTagged() {
      return `<a>${this.phoneAndEmail.email}</a>`
    },
    termsAndConditionsUrl() {
      const lang = this.currentLocale.split('_')[0]
      if (this.termsAndConditionsUrls.hasOwnProperty(lang)) {
        return this.termsAndConditionsUrls[lang]
      }
      return this.termsAndConditionsUrls['en']
    },
    privacyPolicyUrl() {
      const lang = this.currentLocale.split('_')[0]
      if (this.privacyPolicyUrls.hasOwnProperty(lang)) {
        return this.privacyPolicyUrls[lang]
      }
      return this.privacyPolicyUrls['en']
    }
  },
  methods: {
    async beforeOpen() {
      const response = await ActivationService.getServiceDetails(
        this.data.serviceId
      )

      if (!response) {
        return
      }

      this.serviceNameShort = XmlLocalizeService.getValue('name', response)

      this.serviceNameLocKey = XmlLocalizeService.getValue(
        'nameLocKey',
        response
      )

      this.titleLocKey = XmlLocalizeService.getValue(
        'instructionsPopup > titleLocKey',
        response
      )

      this.listTitleLocKey = XmlLocalizeService.getValue(
        'instructionsPopup > list > titleLocKey',
        response
      )

      this.listItemsLocKeys = XmlLocalizeService.getAllValues(
        'instructionsPopup > list > items > item',
        response
      )

      this.linksToGo = XmlLocalizeService.getLocalizations(
        response,
        'instructionsPopup > linkToGo'
      ).versionsByCountry

      const showAdditionalData =
        XmlLocalizeService.getValue(
          'instructionsPopup > showAdditionalData',
          response
        ) === 'true'

      if (showAdditionalData) {
        const additionalData = await ActivationService.fetchServiceAdditionalData(
          this.data.serviceId
        )
        if (!additionalData || additionalData.errorCode) {
          return
        }
        this.vouchers = additionalData.vouchers
      }
    },
    updateFooterText() {
      let termsstart = '{termsstart}'
      let termsend = '{termsend}'
      let privacystart = '{privacystart}'
      let privacyend = '{privacyend}'
      this.footerMessage = this.$t(this.footerLocKey, {
        termsstart,
        termsend,
        privacystart,
        privacyend
      })
    },
    enableRefreshOnClose() {
      this.refreshOnClose = true
    },
    closeWithOptionalRefresh() {
      if (this.refreshOnClose) {
        this.$store.dispatch('customer/fetch').then(response => {
          this.$root.$emit('update-fsdata')
        })
      }
      this.closePopup()
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-popup.wide.activation /deep/ {
  > .box {
    max-width: 850px;

    .popup-content {
      padding-bottom: 42px;
    }
  }

  h2 {
    font-size: 2.2rem;
  }
}
</style>
