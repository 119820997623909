var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "view-content" },
    [_c("dynamic-iframe", { attrs: { "d-src": _vm.iframeSrc } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }