<template>
  <one-col-box-grid-content-box>
    <template v-if="showSuccess">
      <h2>
        {{ $t('enable_autorenew.hl_enable_subscription_success') }}
      </h2>
      <p>
        <span class="icon">
          <img
            src="../assets/images/icons/icon_status_green_fill_small.svg"
            alt
          />
        </span>
        {{
          $t('enable_autorenew.enable_subscription_success_desc', {
            currency: billingCurrency,
            nextBillingDate: nextBillingDate,
            amount: billingAmount
          })
        }}
      </p>
      <base-button-link
        class="primary"
        to="/portal/order"
        @click.native="resetData"
      >
        {{ $t('enable_autorenew.btn_enable_subscription_success_back') }}
      </base-button-link>
    </template>
    <template v-else>
      <h2>
        {{ $t('enable_autorenew.hl_enable_subscription') }}
      </h2>
      <p>
        {{ $t('enable_autorenew.hl_enable_subscription_description') }}
      </p>
      <p>
        {{
          $t('enable_autorenew.hl_enable_subscription_description2', {
            nextBillingDate: nextBillingDate
          })
        }}
      </p>
      <base-button-link
        class="primary"
        prevent-click
        to="/portal/order"
        @click.native="enableAutorenew"
        :class="{ loading }"
      >
        {{ $t('enable_autorenew.btn_enable') }}
      </base-button-link>
      <base-button-link
        class="neutral"
        to="/portal/order"
        @click.native="resetData"
      >
        {{ $t('enable_autorenew.btn_cancel_back') }}
      </base-button-link>
      <p>
        {{
          $t('enable_autorenew.hl_enable_subscription_description3', {
            nextBillingDate: nextBillingDate,
            currency: billingCurrency,
            amount: billingAmount
          })
        }}
      </p>
    </template>
  </one-col-box-grid-content-box>
</template>

<script>
import SubscriptionService from '@/services/SubscriptionService.js'
import { mapGetters } from 'vuex'
import OneColBoxGridContentBox from './OneColBoxGridContentBox.vue'
import BaseButtonLink from '@/components/BaseButtonLink.vue'
import DataMixin from '@/mixins/data'
export default {
  data() {
    return {
      enableRequestSent: false,
      enableSuccess: false,
      loading: false,
      nextBillingDate: null,
      billingAmount: null,
      billingCurrency: null
    }
  },
  mixins: [DataMixin],
  components: {
    OneColBoxGridContentBox,
    BaseButtonLink
  },
  mounted() {
    /* Don't use computed since the success page still
    need this value and it will be overridden by the next get_customer call */
    if (this.$store.state.customer.autorenewOptions) {
      this.nextBillingDate = this.$store.state.customer.autorenewOptions.nextBillingDate
      this.billingCurrency = this.$store.state.customer.autorenewOptions.billingCurrency
      this.billingAmount = this.$store.state.customer.autorenewOptions.billingPrice
    }
  },
  computed: {
    ...mapGetters({
      expirationDateFormatted: 'customer/contract/expirationDateFormatted'
    }),
    showSuccess() {
      return this.enableRequestSent && this.enableSuccess
    }
  },
  methods: {
    resetData() {
      this.enableRequestSent = false
      this.enableSuccess = false
    },
    async enableAutorenew() {
      if (this.loading) {
        return
      }

      this.loading = true
      const response = await this.preHandleResponse(() =>
        SubscriptionService.postEnableAutorenewal(
          this.$store.state.customer.token
        )
      )
      this.loading = false

      if (!response || response.errorCode) {
        this.enableRequestSent = true
        return
      }

      this.enableSuccess = true
      this.enableRequestSent = true
      this.$store.dispatch('customer/fetch')
      this.$store.dispatch('ui/fetchCart')
    }
  }
}
</script>

<style lang="scss" scoped>
.icon {
  img {
    width: 21px;
    margin-bottom: -6px;
  }
}
</style>
