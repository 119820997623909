var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "status-info" }, [
    _c("span", { staticClass: "icon" }, [
      _c("img", { attrs: { src: _vm.iconUrl, alt: "" } })
    ]),
    _c(
      "span",
      { staticClass: "message" },
      [
        _vm._v("\n    " + _vm._s(_vm.message) + "\n    "),
        _vm.linkUrl
          ? _c("router-link", { attrs: { to: _vm.linkUrl } }, [
              _vm._v(_vm._s(_vm.linkText))
            ])
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }