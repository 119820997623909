var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-view", {
    ref: "base-view",
    scopedSlots: _vm._u([
      {
        key: "top",
        fn: function() {
          return [
            _c("the-header", {
              attrs: { "is-nav-shown": "", "no-second-section": "" }
            }),
            _c("the-title-section", {
              attrs: {
                title: _vm.$t("registration.hl_fsecure_service"),
                subTitle: _vm.$t(
                  "registration.hl_protection_for_all_your_needs"
                )
              }
            }),
            _c(
              "one-col-box",
              {
                style: { "margin-bottom": _vm.marginBottom },
                attrs: { "no-padding": "" }
              },
              [
                _c("the-down-for-maintenance-section"),
                _c("avenue-iframe-background")
              ],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "bottom",
        fn: function() {
          return [_c("the-region-picker"), _c("the-help"), _c("the-footer")]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }