<template>
  <base-view>
    <template #top>
      <the-header is-nav-shown />
      <the-title-section :title="mainTitle" :sub-title="subTitle" />
      <one-col-box-grid-content start-higher>
        <the-confirmation-box />
        <the-causes-box />
      </one-col-box-grid-content>
    </template>
    <template #bottom>
      <the-region-picker />
      <the-help />
      <the-footer />
    </template>
  </base-view>
</template>
<script>
import TheHeader from '@/components/TheHeader.vue'
import TheHelp from '@/components/TheHelp.vue'
import TheRegionPicker from '@/components/TheRegionPicker.vue'
import TheFooter from '@/components/TheFooter.vue'
import BaseView from '@/components/BaseView.vue'
import TheTitleSection from '@/components/TheTitleSection.vue'
import OneColBoxGridContent from '@/components/OneColBoxGridContent.vue'
import TheConfirmationBox from '@/components/TheDeleteUserConfirmationViewConfirmationBox.vue'
import TheCausesBox from '@/components/TheDeleteUserConfirmationViewCausesBox.vue'

export default {
  components: {
    TheHeader,
    TheTitleSection,
    TheRegionPicker,
    TheHelp,
    TheFooter,
    BaseView,
    OneColBoxGridContent,
    TheConfirmationBox,
    TheCausesBox
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$root.$emit('route-entered', to.path, from.path)
      vm.$root.$emit('account-view-entered', to.path, from.path)
    })
  },
  computed: {
    mainTitle() {
      return this.$t('account.hl_account')
    },
    subTitle() {
      return this.$t('account.sl_account')
    }
  }
}
</script>

<style lang="scss" scoped>
@media (min-width: $desktop-min-width) {
  .one-col-box.grid /deep/ .content {
    grid-gap: 0 70px;
    background-position: center;
    background-size: 2px 80%;
    background-image: linear-gradient(#f5f5f5, #f5f5f5);
    background-repeat: no-repeat;

    .flex-btn {
      width: auto;
      min-width: 106px;

      + .flex-btn {
        margin-left: 10px;
      }
    }
  }
}
</style>
