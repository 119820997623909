<template>
  <div class="input-wrapper" :class="{ loading }">
    <label :for="$attrs.id" v-if="label">
      <span>{{ label }}</span>
    </label>
    <base-input :value="value" v-bind="$attrs" v-on="$listeners" ref="input" />
  </div>
</template>

<script>
import BaseInput from './BaseInput.vue'
export default {
  inheritAttrs: false,
  props: {
    loading: {
      type: Boolean
    },
    value: [String, Number],
    label: String
  },
  components: {
    BaseInput
  }
}
</script>

<style lang="scss" scoped>
.input-wrapper {
  &.loading {
    position: relative;

    &::before {
      content: '';
      background: url('../assets/images/spinner.svg');
      background-repeat: repeat;
      background-size: auto;
      position: absolute;
      z-index: 2;
      width: 100%;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center right;
      bottom: 0;
      height: 50px;
    }
  }

  &.error {
    input {
      border-color: $danger-color;
    }
  }

  + .flex-btn {
    margin-top: 40px;
  }

  + p:not(.error-message) {
    margin-top: 32px;
  }
}

@media (min-width: $desktop-min-width) {
  .input-wrapper {
    &.one-line-desktop /deep/ {
      width: auto;
      display: inline-block;

      + .flex-btn {
        margin-top: 0;
        margin-left: 10px;
      }

      input {
        width: auto;

        + .flex-btn {
          margin-top: 0;
          margin-left: 10px;
        }
      }
    }
  }
}
</style>
