var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "one-col-box",
    _vm._b(
      { class: { "less-close-padding": _vm.lessClosePadding } },
      "one-col-box",
      _vm.$props,
      false
    ),
    [
      _vm.closeButtonUrl
        ? _c(
            "router-link",
            { staticClass: "close", attrs: { to: _vm.closeButtonUrl } },
            [
              _c("span", [
                _c("picture", [
                  _c("img", {
                    attrs: {
                      src: require("../assets/images/icons/icon_close.svg"),
                      alt: "close"
                    }
                  }),
                  _c("img", {
                    attrs: {
                      src: require("../assets/images/icons/icon_close_hover.svg"),
                      alt: "close"
                    }
                  })
                ])
              ])
            ]
          )
        : _vm._e(),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }