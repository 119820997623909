var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "inner-content" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.accepted,
            expression: "!accepted"
          }
        ],
        staticClass: "activation-view"
      },
      [
        _c(
          "div",
          {
            staticClass: "info-wrapper",
            class: { "left-aligned": _vm.isInfoLeftAligned }
          },
          [
            _c("h2", [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t("activation.hl_activate_service", {
                      servicename: _vm.serviceName
                    })
                  ) +
                  "\n      "
              )
            ]),
            _c("p", { domProps: { innerHTML: _vm._s(_vm.infoText) } }),
            _c("p", { domProps: { innerHTML: _vm._s(_vm.learnMoreText) } }),
            _c("p", { domProps: { innerHTML: _vm._s(_vm.termsText) } })
          ]
        ),
        _c(
          "div",
          { staticClass: "buttons" },
          [
            _c(
              "base-button",
              {
                staticClass: "primary",
                attrs: {
                  id: "btn-additional-service-activate",
                  "data-track-event": "navigate",
                  "data-track-name": "activate-service"
                },
                on: { click: _vm.sendAccept }
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("activation.btn_accept_and_activate")) +
                    "\n      "
                )
              ]
            ),
            _c(
              "base-button",
              {
                staticClass: "neutral grey",
                attrs: {
                  id: "btn-additional-service-no-thanks",
                  "data-track-event": "navigate",
                  "data-track-name": "cancel-activate-service"
                },
                on: { click: _vm.closePopup }
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("activation.btn_no_thanks")) +
                    "\n      "
                )
              ]
            )
          ],
          1
        )
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.accepted,
            expression: "accepted"
          }
        ],
        staticClass: "activated-view"
      },
      [
        _c("h2", [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("activation.hl_congratulations")) +
              "\n    "
          )
        ]),
        _vm._m(0),
        _c("h3", { staticClass: "success" }, [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.$t(_vm.successMessageLocalizationKey, {
                  servicename: _vm.serviceName
                })
              ) +
              "\n    "
          )
        ]),
        _vm.isActivatedOnlyOnMyfs
          ? _c("p", [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.$t(
                      "activation.ntc_to_take_advantage_of_benefit_go_to_subscription",
                      {
                        servicename: _vm.serviceName
                      }
                    )
                  ) +
                  "\n    "
              )
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "buttons" },
          [
            _c(
              "base-button",
              {
                staticClass: "primary",
                attrs: {
                  id: "btn-additional-service-close",
                  "data-track-event": "navigate",
                  "data-track-name": "close-activate-service"
                },
                on: { click: _vm.closePopup }
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.closeButtonTextComputed) +
                    "\n      "
                )
              ]
            )
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("picture", [
      _c("img", {
        attrs: {
          src: require("../assets/images/icons/icon_status_green_small.svg"),
          alt: "check"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }