<template>
  <base-header-bar-link
    data-track-event="navigate"
    :data-track-name="dataTrackName"
    :to="toUrl"
    v-show="isVisible"
  >
    <span>{{ productName }}</span>
  </base-header-bar-link>
</template>

<script>
import { mapState } from 'vuex'
import BaseHeaderBarLink from './BaseHeaderBarLink.vue'
export default {
  props: {
    productShortName: {
      type: String,
      required: true
    }
  },
  components: {
    BaseHeaderBarLink
  },
  computed: {
    ...mapState({
      couponShareProductNames: state => state.ui.couponShareProductNames
    }),
    toUrl() {
      return '/portal/coupon/share/' + this.$props.productShortName
    },
    dataTrackName() {
      return 'portal-share-' + this.$props.productShortName
    },
    productName() {
      return this.couponShareProductNames[this.$props.productShortName]
    },
    isVisible() {
      return !!this.productName
    }
  }
}
</script>

<style lang="scss" scoped>
@media (min-width: $desktop-min-width) {
  a {
    color: $font-color;
    &:visited {
      color: $font-color;
    }
  }
}
</style>
