var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-settings-content-section",
    { staticClass: "overview", attrs: { id: "overview-id" } },
    [
      _c("h2", [_vm._v(_vm._s(_vm.$t("subscription.hl_overview")))]),
      _c("subscription-product-overview-content")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }