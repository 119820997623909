var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "item", attrs: { id: _vm.serviceId } }, [
    _c("div", { staticClass: "title" }, [
      _c("h3", [_vm._v(_vm._s(_vm.serviceName))]),
      _c("p", [_vm._v(_vm._s(_vm.$t("subscription.txt_active")))])
    ]),
    _c("div", { staticClass: "content" }, [
      _c("p", [
        _vm._v("\n      " + _vm._s(_vm.startText) + "\n      "),
        _vm.hasLink
          ? _c(
              "a",
              {
                attrs: {
                  "data-track-event": "navigate",
                  "data-track-name": "additional-service-info",
                  target: "_blank",
                  rel: "noopener noreferrer",
                  href: _vm.infoUrl
                },
                on: { click: _vm.navigateOrOpenPopup }
              },
              [_vm._v(_vm._s(_vm.linkText))]
            )
          : _vm._e(),
        _vm._v("\n      " + _vm._s(_vm.endText) + "\n    ")
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }