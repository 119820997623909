<template>
  <base-view ref="base-view">
    <template #top>
      <the-header no-second-section />
      <the-title-section
        :title="$t('registration.hl_fsecure_service')"
        :subTitle="$t('registration.hl_protection_for_all_your_needs')"
      />
      <one-col-box no-padding>
        <the-token-error-section />
      </one-col-box>
    </template>
    <template #bottom>
      <the-region-picker />
      <the-help />
      <the-footer />
    </template>
  </base-view>
</template>
<script>
import TheHeader from '@/components/TheHeader.vue'
import TheHelp from '@/components/TheHelp.vue'
import TheRegionPicker from '@/components/TheRegionPicker.vue'
import TheFooter from '@/components/TheFooter.vue'
import BaseView from '@/components/BaseView.vue'
import TheTitleSection from '@/components/TheTitleSection.vue'
import TheTokenErrorSection from '@/components/TheTokenErrorSection.vue'
import OneColBox from '@/components/OneColBox.vue'

export default {
  components: {
    TheHeader,
    TheTitleSection,
    TheTokenErrorSection,
    TheRegionPicker,
    TheHelp,
    TheFooter,
    BaseView,
    OneColBox
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$root.$emit('token-login-error-view-entered', to.path, from.path)
    })
  }
}
</script>

<style lang="scss" scoped></style>
