var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "the-maintenance-title-section" }, [
    _c("div", [
      _c("h1", [_vm._v(_vm._s(_vm.$t("login.hl_token_error")))]),
      _c("p", [_vm._v(_vm._s(_vm.$t("login.txt_token_error")))])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }