var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-modal-popup",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isOpen,
          expression: "isOpen"
        }
      ],
      staticClass: "subscription-reminder",
      class: { notice: _vm.isNotice, alert: _vm.isAlert, ok: _vm.isOk },
      attrs: {
        id: "subscription-reminder",
        "display-footer": _vm.displayFooter,
        "display-close-button": "",
        "on-close-click": _vm.closePopup,
        "more-padding": "",
        "is-wide": "",
        "more-footer-padding": !_vm.isFooterTextEmpty
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _vm.footerText
                ? _c("p", { domProps: { innerHTML: _vm._s(_vm.footerText) } })
                : _vm._e(),
              _vm.footerButtonText
                ? _c(
                    "base-button-link-native",
                    {
                      staticClass: "primary",
                      attrs: { href: _vm.footerButtonUrl }
                    },
                    [_vm._v(_vm._s(_vm.footerButtonText))]
                  )
                : _vm._e(),
              _vm.showFooterCouponLink
                ? _c(
                    "router-link",
                    {
                      attrs: { to: "/portal/coupon/renew" },
                      nativeOn: {
                        click: function($event) {
                          return _vm.closePopup($event)
                        }
                      }
                    },
                    [_vm._v("\n      " + _vm._s(_vm.footerLinkText) + "\n    ")]
                  )
                : _vm._e()
            ]
          },
          proxy: true
        }
      ])
    },
    [
      [
        _c("h2", [_vm._v(_vm._s(_vm.popupTitle))]),
        _c(
          "div",
          {
            staticClass: "content-row",
            class: {
              "valign-to-top": _vm.teaserText,
              centered: _vm.isContentCentered,
              "no-bottom-padding": _vm.isButtonTextEmpty
            }
          },
          [
            _vm.popupImage
              ? _c("div", { staticClass: "icon" }, [
                  _c("img", { attrs: { src: _vm.popupImage, alt: "" } })
                ])
              : _vm._e(),
            _c("div", { staticClass: "text" }, [
              _vm.popupSubTitle
                ? _c("h3", [_vm._v(_vm._s(_vm.popupSubTitle))])
                : _vm._e(),
              _c("p", {
                domProps: {
                  innerHTML: _vm._s(_vm.popupText.replace(/\n/g, "<br>"))
                }
              }),
              _vm.showSecondParagraph
                ? _c("p", [
                    _vm.popupTextSecond
                      ? _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.popupTextSecond.replace(/\n/g, "<br />") +
                                "&nbsp;"
                            )
                          }
                        })
                      : _vm._e(),
                    _vm.contentLinkText
                      ? _c("a", { attrs: { href: _vm.contentLinkUrl } }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.contentLinkText) +
                              "\n          "
                          )
                        ])
                      : _vm._e()
                  ])
                : _vm._e(),
              _vm.teaserText
                ? _c("p", { staticClass: "teaser" }, [
                    _vm._v(_vm._s(_vm.teaserText))
                  ])
                : _vm._e()
            ])
          ]
        ),
        _vm.buttonText
          ? _c("base-purchase-button", {
              ref: "purchase-button",
              staticClass: "primary",
              attrs: {
                text: _vm.buttonText,
                url: _vm.buttonUrl,
                "no-click-bind": !_vm.navigateToBuy
              },
              nativeOn: {
                click: function($event) {
                  return _vm.closeOrNavigate($event)
                }
              }
            })
          : _vm._e(),
        _vm.showSecondButton
          ? _c(
              "base-button",
              { staticClass: "neutral", on: { click: _vm.closePopup } },
              [_vm._v(_vm._s(_vm.$t("reminder.btn_remindmelater")))]
            )
          : _vm._e()
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }