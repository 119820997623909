<template>
  <base-view is-blue-bg-view overlayed>
    <template #top>
      <the-header is-nav-shown />
      <one-col-box-with-close
        no-transform
        add-space-to-top
        apply-top-margin
        close-button-url="/portal/order"
      >
        <div class="popup-content">
          <h2>{{ $t('enable_autorenew_reason.hl_reason') }}</h2>
          <ul>
            <li v-html="$t('enable_autorenew_reason.reason1')"></li>
            <li v-html="$t('enable_autorenew_reason.reason2')"></li>
            <li v-html="$t('enable_autorenew_reason.reason3')"></li>
          </ul>
          <base-button-link class="neutral" to="/portal/order">
            {{ $t('enable_autorenew_reason.btn_back') }}
          </base-button-link>
        </div>
      </one-col-box-with-close>
    </template>
    <template #bottom>
      <the-region-picker />
      <the-help />
      <the-footer />
    </template>
  </base-view>
</template>
<script>
import TheHeader from '@/components/TheHeader.vue'
import TheHelp from '@/components/TheHelp.vue'
import TheRegionPicker from '@/components/TheRegionPicker.vue'
import TheFooter from '@/components/TheFooter.vue'
import BaseView from '@/components/BaseView.vue'
import OneColBoxWithClose from '@/components/OneColBoxWithClose.vue'
import BaseButtonLink from '@/components/BaseButtonLink.vue'

export default {
  components: {
    TheHeader,
    TheRegionPicker,
    TheHelp,
    TheFooter,
    BaseView,
    OneColBoxWithClose,
    BaseButtonLink
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$root.$emit('route-entered', to.path, from.path)
    })
  }
}
</script>

<style lang="scss" scoped>
/deep/ .one-col-box .content {
  padding: 20px;
}
@media (max-width: 520px) {
  /deep/ .one-col-box .content {
    padding: 0 !important;
  }
  a.close {
    top: 20px;
    right: 20px;
  }
}
.popup-content {
  padding: 32px 20px 20px 20px;
  h2 {
    color: $primary-color;
    font-size: 2.5em;
    line-height: 1.21886em;
    margin-top: 0;
    font-weight: 600;
    text-align: center;
  }
  ul {
    padding: 0 64px;
    list-style: none;
    li {
      :before {
        content: '✓';
        display: block;
        float: left;
        margin-left: -25px;
      }
      font-size: 1.2em;
      padding-bottom: 33px;
    }
  }
}
</style>
