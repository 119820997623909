var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.doExist
    ? _c("iframe", {
        attrs: { src: _vm.iframeUrlManaged, frameborder: "0" },
        on: { load: _vm.onLoad }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }