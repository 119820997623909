var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.customerIsActivated
    ? _c(
        "div",
        {
          staticClass: "avenue-iframe",
          style: { marginTop: _vm.top, height: _vm.height }
        },
        [
          _vm.loadSafeIframe
            ? _c("iframe", {
                ref: "safe-iframe",
                style: {
                  position: _vm.showSafeIframe ? "relative" : "absolute",
                  visibility: _vm.showSafeIframe ? "visible" : "hidden",
                  height: _vm.currentIframeHeight
                },
                attrs: { src: _vm.iframeUrlSafe, frameborder: "0" },
                on: { load: _vm.markSafeLoadedAndLoadNextIframe }
              })
            : _vm._e(),
          _vm.loadFreedomeIframe
            ? _c("iframe", {
                ref: "freedome-iframe",
                style: {
                  position: _vm.showFreedomeIframe ? "relative" : "absolute",
                  visibility: _vm.showFreedomeIframe ? "visible" : "hidden",
                  height: _vm.currentIframeHeight
                },
                attrs: { src: _vm.iframeUrlFreedome, frameborder: "0" },
                on: { load: _vm.markFreedomeLoadedAndLoadNextIframe }
              })
            : _vm._e(),
          _vm.loadKeyIframe
            ? _c("iframe", {
                ref: "key-iframe",
                style: {
                  position: _vm.showKeyIframe ? "relative" : "absolute",
                  visibility: _vm.showKeyIframe ? "visible" : "hidden",
                  height: _vm.currentIframeHeight
                },
                attrs: { src: _vm.iframeUrlKey, frameborder: "0" },
                on: { load: _vm.markKeyLoadedAndLoadNextIframe }
              })
            : _vm._e(),
          _vm.loadKeyIdpIframe
            ? _c("iframe", {
                ref: "idp-iframe",
                style: {
                  position: _vm.showKeyIdpIframe ? "relative" : "absolute",
                  visibility: _vm.showKeyIdpIframe ? "visible" : "hidden",
                  height: _vm.currentIframeHeight
                },
                attrs: { src: _vm.iframeUrlKeyIdp, frameborder: "0" },
                on: { load: _vm.markKeyIdpLoadedAndLoadNextIframe }
              })
            : _vm._e()
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }