var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isVisible
    ? _c(
        "base-settings-content-section",
        { attrs: { id: "discount-code-id" } },
        [
          _c("h2", [_vm._v(_vm._s(_vm.$t("coupon_renew.hl_discount_code")))]),
          _vm.isDiscountApplied
            ? _c("div", { staticClass: "discount-applied" }, [
                _c("p", { staticClass: "success" }, [
                  _c("span", { staticClass: "icon" }, [
                    _c("img", {
                      attrs: {
                        src: require("../assets/images/icons/icon_status_green_small.svg"),
                        alt: ""
                      }
                    })
                  ]),
                  _c("span", [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("coupon_renew.code_applied")) +
                        "\n      "
                    )
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "price-block" },
                  [
                    _c("p", { staticClass: "price" }, [
                      _c(
                        "span",
                        { staticClass: "original-price center-strike" },
                        [_vm._v(_vm._s(_vm.originalPriceProcessed))]
                      ),
                      _vm._v("    "),
                      _c("span", { staticClass: "discounted-price" }, [
                        _vm._v(_vm._s(_vm.discountedPriceProcessed))
                      ])
                    ]),
                    _vm.isAutomaticSubscription
                      ? _c("p", { staticClass: "text legend" }, [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm.$t("coupon_renew.discounted_price_billed")
                              ) +
                              "\n      "
                          )
                        ])
                      : _c("base-purchase-button", { staticClass: "primary" })
                  ],
                  1
                )
              ])
            : _c(
                "div",
                { staticClass: "discount-form" },
                [
                  _c("base-loading-input-wrapper", {
                    ref: "code-input-wrapper",
                    staticClass: "one-line-desktop",
                    class: { error: _vm.formError },
                    attrs: {
                      type: "text",
                      placeholder: _vm.inputPlaceholder,
                      loading: _vm.isApplyingCode,
                      disabled: _vm.isApplyingCode
                    },
                    on: { blur: _vm.resetForm, keyup: _vm.validateFormErrors },
                    model: {
                      value: _vm.codeInput,
                      callback: function($$v) {
                        _vm.codeInput = $$v
                      },
                      expression: "codeInput"
                    }
                  }),
                  _vm.formError
                    ? [
                        !_vm.$v.codeInput.required
                          ? _c("p", { staticClass: "error-message" }, [
                              _vm._v(
                                "\n        " +
                                  _vm._s(
                                    _vm.$t("coupon_renew.form_field_required")
                                  ) +
                                  "\n      "
                              )
                            ])
                          : _c("p", { staticClass: "error-message" }, [
                              _vm._v(
                                "\n        " +
                                  _vm._s(
                                    _vm.$t(
                                      "subscription.error_discount_code_invalid"
                                    )
                                  ) +
                                  "\n      "
                              )
                            ])
                      ]
                    : _vm._e(),
                  _c(
                    "base-button",
                    {
                      staticClass: "neutral",
                      class: { disabled: _vm.isButtonDisabled },
                      attrs: {
                        disabled: _vm.isButtonDisabled,
                        "data-track-event": "navigate",
                        "data-track-name": "apply-discount"
                      },
                      on: { click: _vm.validateCode }
                    },
                    [
                      _vm._v(
                        "\n      " +
                          _vm._s(_vm.$t("coupon_renew.btn_apply_code")) +
                          "\n    "
                      )
                    ]
                  )
                ],
                2
              )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }