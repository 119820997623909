var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "one-col-box-grid-content-box",
    [
      _c("h2", [_vm._v(_vm._s(_vm.$t("account.hl_contact")))]),
      _c("p", [_vm._v(_vm._s(_vm.$t("account.sl_contact")))]),
      _c(
        "base-button-link",
        {
          staticClass: "primary",
          attrs: {
            id: "btn-edit-account",
            to: "/portal/contact/update",
            "data-track-event": "navigate",
            "data-track-name": "account-update"
          }
        },
        [_vm._v("\n    " + _vm._s(_vm.$t("account.btn_editdetails")) + "\n  ")]
      ),
      _c(
        "base-button-link",
        {
          staticClass: "primary mobile-only",
          attrs: {
            id: "btn-mobile-logout",
            to: "/logout",
            "data-track-event": "navigate",
            "data-track-name": "logout"
          }
        },
        [_vm._v("\n    " + _vm._s(_vm.$t("account.btn_log_out")) + "\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }