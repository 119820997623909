var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-view", {
    scopedSlots: _vm._u([
      {
        key: "top",
        fn: function() {
          return [
            _c("the-header", {
              attrs: { "no-shadow": "", "is-even-wider": "" }
            }),
            _c("the-title-section", {
              attrs: {
                title: _vm.title,
                "is-padded": "",
                "is-mobile-centered": ""
              }
            }),
            _c("two-col-box", {
              attrs: { "is-even-wider": "" },
              scopedSlots: _vm._u([
                {
                  key: "col1",
                  fn: function() {
                    return [_c("the-login-left-section")]
                  },
                  proxy: true
                },
                {
                  key: "col2",
                  fn: function() {
                    return [_c("the-register-right-section")]
                  },
                  proxy: true
                }
              ])
            })
          ]
        },
        proxy: true
      },
      {
        key: "bottom",
        fn: function() {
          return [
            _c("the-region-picker"),
            _c("the-help"),
            _c("the-footer", { attrs: { "is-visible-on-mobile": "" } })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }