var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "voucher-item" }, [
    _c("div", { staticClass: "heading" }, [
      _vm._v("\n    " + _vm._s(_vm.heading) + "\n  ")
    ]),
    _c("div", { staticClass: "content" }, [
      _c("span", { staticClass: "voucher" }, [
        _vm._v("\n      " + _vm._s(_vm.voucher) + "\n    ")
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }