var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isVisible
    ? _c("one-col-box-grid-content-box", [
        _c("h2", [_vm._v(_vm._s(_vm.$t("account.hl_other_settings")))]),
        _c(
          "p",
          [
            _vm._v("\n    " + _vm._s(_vm.slOtherSettings) + "\n    "),
            _c("router-link", { attrs: { to: "/portal/order" } }, [
              _vm._v("\n      " + _vm._s(_vm.slOtherSettingsText) + "\n    ")
            ])
          ],
          1
        ),
        _vm.isDeleteUserLinkVisible
          ? _c(
              "p",
              [
                _vm._v("\n    " + _vm._s(_vm.slDeleteUser) + "\n    "),
                _c(
                  "router-link",
                  { attrs: { to: "/portal/delete-user/confirmation" } },
                  [_vm._v("\n      " + _vm._s(_vm.slDeleteUserText) + "\n    ")]
                )
              ],
              1
            )
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }