var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-view", {
    attrs: { "is-blue-bg-view": "", overlayed: "" },
    scopedSlots: _vm._u([
      {
        key: "top",
        fn: function() {
          return [
            _c("the-header", { attrs: { "is-nav-shown": "" } }),
            _c(
              "one-col-box-with-close",
              {
                attrs: {
                  "no-transform": "",
                  "add-space-to-top": "",
                  "apply-top-margin": "",
                  "close-button-url": "/portal/complete-registration"
                }
              },
              [_c("the-start-trial-content")],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "bottom",
        fn: function() {
          return [_c("the-region-picker"), _c("the-help"), _c("the-footer")]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }