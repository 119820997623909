var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-settings-content-section",
    { staticClass: "summary", attrs: { id: "summary-id" } },
    [
      _c("h2", { attrs: { id: "hl-subscription-summary" } }, [
        _vm._v(
          "\n    " +
            _vm._s(_vm.$t("subscription.hl_subscription_summary")) +
            "\n  "
        )
      ]),
      _c(
        "div",
        { staticClass: "grid", attrs: { id: "subscription-summary-item" } },
        [
          _c("subscription-view-summary-item", {
            attrs: {
              title: _vm.$t("subscription.hl_product_type"),
              content: _vm.productType
            }
          }),
          _vm.displayExpirationDate
            ? _c("subscription-view-summary-item", {
                attrs: {
                  title: _vm.$t("subscription.hl_expiration_date"),
                  content: _vm.expirationDateFormatted
                }
              })
            : _vm._e(),
          _c("subscription-view-summary-item", {
            attrs: {
              title: _vm.$t("subscription.hl_status"),
              content: _vm.subscriptionStatusText
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }