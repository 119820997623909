var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "action-button" },
    [
      _c("base-purchase-button", {
        ref: "BasePurchaseButton",
        style: { height: _vm.heightCss }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }