var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("img", {
      attrs: { src: require("../assets/images/start-trial-logo.svg"), alt: "" }
    }),
    _c("div", { staticStyle: { "text-align": "left" } }, [
      _c("h1", [
        _vm._v(_vm._s(_vm.$t("complete_registration.start_trial_heading")))
      ]),
      _c("div", {
        staticClass: "tag",
        domProps: {
          innerHTML: _vm._s(
            _vm.$t("complete_registration.start_trial_tag", {
              license_period_in_days: _vm.licensePeriodInDays
            })
          )
        }
      }),
      _c("h3", [
        _vm._v(
          "\n      " +
            _vm._s(
              _vm.$t("complete_registration.start_trial_sub_heading", {
                full_product_name: _vm.fullProductName,
                license_period_in_days: _vm.licensePeriodInDays
              })
            ) +
            "\n    "
        )
      ]),
      _c("span", [
        _vm._v(
          "\n      " +
            _vm._s(
              _vm.$t("complete_registration.start_trial_desc", {
                license_period_in_days: _vm.licensePeriodInDays
              })
            ) +
            "\n    "
        )
      ]),
      _vm.defaultTrialConfig.serviceKey === "TOTAL"
        ? _c("ul", [
            _c("li", [
              _vm._v(
                _vm._s(_vm.$t("complete_registration.start_trial_product_safe"))
              )
            ]),
            _c("li", [
              _vm._v(
                _vm._s(
                  _vm.$t("complete_registration.start_trial_product_freedome")
                )
              )
            ]),
            _c("li", [
              _vm._v(
                _vm._s(_vm.$t("complete_registration.start_trial_product_idp"))
              )
            ])
          ])
        : _vm.defaultTrialConfig.productVariant === "total_standard"
        ? _c("ul", [
            _c("li", [_vm._v(_vm._s(_vm.unifiedProductNames.epp))]),
            _c("li", [_vm._v(_vm._s(_vm.unifiedProductNames.unifiedVpn))]),
            _c("li", [_vm._v(_vm._s(_vm.unifiedProductNames.unifiedIdp))])
          ])
        : _vm._e(),
      _c(
        "div",
        { staticStyle: { "text-align": "right" } },
        [
          _c(
            "base-button",
            { staticClass: "primary", on: { click: _vm.startTrial } },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.startingTrial,
                      expression: "!startingTrial"
                    }
                  ]
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t("complete_registration.start_trial_btn_label")
                    )
                  )
                ]
              ),
              _c(
                "picture",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.startingTrial,
                      expression: "startingTrial"
                    }
                  ]
                },
                [
                  _c("img", {
                    staticStyle: { color: "white", "max-width": "30%" },
                    attrs: {
                      src: require("@/assets/images/spinner-white.svg"),
                      alt: ""
                    }
                  })
                ]
              )
            ]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }